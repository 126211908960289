import { PageHeader, MetaTagHeader } from '@components'
import { beTagsFlat } from '@helpers/constants';
import { routesFlat } from "@routes";
import {
  FeatsAndBenefits,
  Method,
  ExploreTitleAndDesc,
  ExploreTitleOnly,
  ProductNews,
} from "../../components";
import { header, featsAndBenefits, desc, superscript } from "./constants";

const metaProps = {
  title:
    "Measure carbon stocks, emissions, and removals for forests in any jurisdiction",
  desc: "CTrees offers data for all jurisdictions globally to measure, report, and verify their carbon stocks, emissions, and removals from land. Explore free data in our jurisdictional MRV tool.",
  img: "",
};

export default function JMRV() {
  const pageHeader = { ...header, btnLink: routesFlat.contact, product: true };
  const method = { desc, superscript };
  const exploreProps = {
    product: true,
    ctaText: "See annual statistics and maps for countries and jurisdictions",
    link: routesFlat.jmrvMap,
  };

  return (
    <>
      <MetaTagHeader {...metaProps} />
      <PageHeader {...pageHeader} />
      <ExploreTitleAndDesc {...exploreProps}>
        <div className="mb-4">
          CTrees offers data for all jurisdictions globally to measure, report,
          and verify (MRV) their carbon stocks, emissions, and removals from
          land. By scaling up data on carbon flux from {"<"}0.1 hectare levels
          to state, country, and global estimates, CTrees provides consistent
          calculation of emission and removals. Our data is designed for
          jurisdictions to measure progress on climate policies and develop
          jurisdictional carbon credit programs under major standards.
        </div>
        Our free data tool provides annual estimates for more than 185 countries
        and their subnational divisions. Advanced MRV features are available
        through partnerships.
      </ExploreTitleAndDesc>
      <FeatsAndBenefits feats={featsAndBenefits} />
      <Method {...method} />
      {/* <FAQ qArr={faq} /> */}
      <ExploreTitleOnly {...exploreProps} />
      <ProductNews product={beTagsFlat.jmrv} />
    </>
  );
}
