import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import UpArrow from "@assets/up-arrow.svg";
import DownArrow from "@assets/down-arrow.svg";

export const MenuItem = ({ path, display, onClick, nested }) => {
  const [isOpen, setOpen] = useState(true);
  const onLinkClick = () => {
    if (path) {
      onClick();
    }
  };

  return (
    <motion.li
      variants={itemVariants}
      whileHover={!nested && { scale: 1.1 }}
      whileTap={!nested && { scale: 0.95 }}
      onClick={onLinkClick}
      className="md:mt-5 mt-6 md:ml-8 first:mt-20 last:pb-20 list-none"
    >
      <div className="text-xl font-bold text-lightpurple text-center">
        {nested ? (
          <div onClick={() => setOpen(!isOpen)} className="flex justify-center w-full cursor-pointer">
            {display}
            <img
              src={isOpen ? DownArrow : UpArrow}
              alt="dropdown arrow"
              className="fill-[#323232] ml-4"
            />
          </div>
        ) : (
          <Link to={path}>{display}</Link>
        )}
      </div>
      {(isOpen && nested) && <div className="mt-6 md:mb-2">{nested?.map((item) => {
        return (
          <motion.li
            variants={itemVariants}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.95 }}
          >
            <Link to={item.path} onClick={onClick}>
              <div className="md:mt-3 mt-6 text-lg w-auto text-center">
                {item.display}
              </div>
            </Link>
          </motion.li>
        );
      })}</div>}
    </motion.li>
  );
};

const itemVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};
