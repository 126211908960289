export const header = {
  title: "Land Use Change Alerts (LUCA)",
  desc: "Get reliable near-real-time information on disturbances in forests worldwide.",
  img: "https://ctrees-website.s3.amazonaws.com/website_images/product/luca_pg.jpg",
};

// for each feature, add as an object item with title and desc attr inside of featsAndBenefits array
export const featsAndBenefits = [
  {
    title: "Near-real-time information",
    desc: "Track global forest disturbance activity with radar alerts every other week.",
  },
  {
    title: "Reliable alerts system",
    desc: "Our radar-based system works under all weather conditions globally.",
  },
  {
    title: "Historical trends ",
    desc: "Use data from 2018 to present to assess whether a disturbance is a new flare up or a consistent trend.",
  },
  {
    title: "Actionable insights",
    desc: "Enforce laws and design interventions for forest protection.",
  },
  {
    title: "Tracking at all scales",
    desc: "Quantify areas of forest disturbance at state and national levels, or by analyzing a custom area.",
  },
  {
    title: "Global outlook",
    desc: "See alerts for all forest biomes: tropical humid, tropical dry, temperate, and boreal.",
  },
];

// for each paragraph in methodology description, add as string item inside of method array
export const desc = [
  "Land Use Change Alerts (LUCA) is the first global radar-based forest disturbance alert product. LUCA is based on the European Space Agency’s Sentinel-1 C-band synthetic aperture radar (SAR) imagery, which offers high-resolution (10 meter pixels) images of the entire global land under almost all-weather conditions every 6 to 12 days. Use of Sentinel-1 imagery allows for detection of changes in land cover almost at the time that it occurs.",
  "CTrees designed the LUCA platform for all land areas covered by forests, defined as areas with a minimum of 15 percent tree cover, and average tree height of 5 meters. LUCA is built with three algorithmic and data processing steps: (1) initial processing of Sentinel-1 imagery to create pixel-based statistical features of local forest characteristics from 2016 to 2018 using a map of existing forest cover synthesized from optical imagery for the same period; (2) applying state-of-the-art machine learning techniques to detect forest loss at a minimum mapping unit of 0.05 hectares from deforestation, degradation, and other disturbances such as fire; and (3) verifying the forest loss detections using more than 4,000 independent reference samples and improving the final products by filtering false detection. The methodology is based on peer-reviewed papers published in scientific journals [1][2][3].",
  "The alerts are generated approximately every two weeks from January 1, 2018 to present. The first version of LUCA is implemented on the Google Earth Engine platform, providing only alerts of activities occurring in forest areas, including tropical humid, tropical dry, temperate, and boreal biomes. The next version of LUCA, to be launched in late 2024, will integrate more satellite data sources, such as L-band radar imagery from the NASA-ISRO SAR (NISAR) mission to be launched in early 2024. Anticipated features of LUCA v2 include coverage of areas outside of forests, more frequent alerts (< one week), and land use change attributions. ",
];
// for each numbered reference under methodology, add as string item inside of superscript array
export const superscript = [
  "[1] A. Mullissa, A. Vollrath, C. Odongo-Braun, B. Slagter, J. Balling, Y. Gou, N. Gorelick, J. Reiche, Sentinel-1 SAR backscatter analysis ready data preparation in Google Earth Engine, Remote Sensing 13 (10) (2021) 1954.",
  "[2] Mullissa, A. G., Reiche, J.,  Herold, M., Deep Learning and Automatic Reference Label Harvesting for Sentinel-1 SAR-based Rapid Tropical Dry Forest Disturbance Mapping, Remote sensing of Environment (Accepted).",
  "[3] P. Olofsson, G. M. Foody, M. Herold, S. V. Stehman, C. E. Woodcock, M. A.Wulder, Good practices for estimating area and assessing accuracy of land change, Remote sensing of Environment 148 (2014) 42–57.",
];

export const faq = [
  { question: "can we write an FAQ", answer: "bye" },
  { question: "How can I use this data?", answer: "bye" },
];
