import { Link } from "react-router-dom";
import OpacityWrapper from "../OpacityWrapper";
import Skeleton from "react-loading-skeleton";
import { routesFlat } from "@routes";
import { tagStyle } from "../../pages/news";
import { tagsList } from "@helpers/constants";
import "react-loading-skeleton/dist/skeleton.css";

export default function Header({
  img,
  title,
  desc,
  tags,
  alt = "green blue yellow and red colors making an abstract of heatmap",
  product = false,
  article = false,
}) {
  console.log({tags})
  function Img() {
    if (product) {
      return (
        <div
          className={`flex w-auto items-center justify-center md:h-screen md:w-1/2`}
        >
          <div className="flex h-full items-center">
            <img src={img} className="max-h-full max-w-full" />
          </div>
        </div>
      );
    }

    if (article) {
      return (
        <div className={`flex w-full justify-center md:h-auto md:w-1/2`}>
          <img src={img} className="max-h-full max-w-full" />
        </div>
      );
    }

    return (
      <div className="relative flex h-[400px] justify-center md:h-full md:w-1/2">
        <img src={img} className="md:w-full" alt={alt} />
        <div className="absolute z-10 h-screen w-[1px] bg-white/50"></div>
      </div>
    );
  }

  const hasTags = tags?.products?.length || tags?.type?.length;
  return (
    <OpacityWrapper className={parentContainer}>
      <div className="relative flex h-full w-full justify-center md:w-1/2">
        <div className="mt-6 flex h-full w-full max-w-[500px] flex-col items-center justify-center p-4 md:mt-10 md:p-0">
          <div className="w-full text-center text-3xl font-extrabold md:text-5xl">
            {!!title ? (
              title
            ) : (
              <div className="align-center flex w-full flex-col justify-center">
                <Skeleton className="h-[50px] w-[350px]" />
                <Skeleton className="mt-2 h-[50px] w-[450px]" />
                <Skeleton className="mt-2 h-[50px] w-[250px]" />
              </div>
            )}
          </div>
          <div className="tracking-1 mt-4 w-full text-center text-xl md:mt-10">
            {!!desc ? desc : <Skeleton className="h-[30px] w-[100px]" />}
          </div>
          {!!hasTags && (
            <div className="mt-10 flex w-full flex-wrap items-center justify-center">
              {tags?.products.map((tag) => (
                <Link
                  key={tag}
                  className={tagStyle + " mr-4 w-[130px] text-center"}
                  to={routesFlat[tag] ? routesFlat[tag] : routesFlat.news}
                >
                  {tagsList.product[tag]}
                </Link>
              ))}
              {tags?.type.map((tag) => (
                <Link
                  key={tag}
                  className={tagStyle + " mr-4 w-[130px] text-center"}
                  to={routesFlat[tag] ? routesFlat[tag] : routesFlat.news}
                >
                  {tagsList.type[tag]}
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
      <Img />
    </OpacityWrapper>
  );
}

const parentContainer =
  " md:h-screen text-black flex md:flex-row md:my-0" +
  " justify-center items-center w-full flex-col-reverse my-20 overflow-hidden";
