
import { PageHeader, MetaTagHeader } from "@components";
import { routesFlat } from "@routes";
import { beTagsFlat } from "@helpers/constants";
import {
  FeatsAndBenefits,
  Method,
  ExploreTitleAndDesc,
  ProductNews,
} from "../../components";
import { header, featsAndBenefits, desc, superscript } from "./constants";

const metaProps = {
  title: "Map individual trees at local to national scales with CTrees",
  desc: "CTrees' tree-level data provides information on individual trees at local to national scales, including in savannas, urban, and altered landscapes. Learn more about our innovative science.",
  img: "",
};

export default function TreeLevel() {
  const pageHeader = { ...header, btnLink: routesFlat.contact, product: true };
  const method = { desc, superscript };
  const exploreProps = {
    product: true,
    newWindow: true,
    ctaText:
      "CTrees' California canopy height map is available in AWS Open Registry",
    link: routesFlat.treeLevelAWSRegistry,
  };

  return (
    <>
      <MetaTagHeader {...metaProps} />
      <PageHeader {...pageHeader} />
      <ExploreTitleAndDesc {...exploreProps}>
        <div className="mb-4">
          CTrees' tree-level data provides information on individual trees at
          local to national scales. Data includes all trees, whether inside or
          outside forests, such as in savannas, urban, and altered landscapes.
        </div>
        With information on trees’ location, crown size, height and estimated
        carbon content, users can study patterns of disturbance, recovery, and
        growth. Our tree-level data is designed to help plan nature-based
        climate solutions, and to manage wildfire fuel reduction.
      </ExploreTitleAndDesc>
      <FeatsAndBenefits feats={featsAndBenefits} />
      <Method {...method} />
      <ProductNews product={beTagsFlat.treeLevel} />
    </>
  );
}
