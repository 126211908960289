import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { PageHeader, OpacityWrapper, MetaTagHeader } from "../../components";
import { formatDateMonth } from "@helpers";
import { NewsContext } from "@";
import { staticTree, tagsList } from "@helpers/constants";
// import NewsFilter from "./Filter";

const pageHeader = {
    title: "The Latest",
    desc: "Get the latest news, product updates and success stories from CTrees.",
    img: "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images/static/heatmap/5.png",
};

const metaProps = {
    title: "News and updates on global forest carbon from CTrees",
    desc: "CTrees’ data products, science, and partnerships to protect and restore forests are constantly evolving. Learn more about our work.",
    img: "",
};

export default function News() {
    const { articles } = useContext(NewsContext);
    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className="flex w-full flex-col items-center justify-center">
                <PageHeader {...pageHeader} />
                {/* <NewsFilter /> */}
                <div className="flex h-full w-full flex-wrap justify-around border-t-[1px] border-black/[10%] p-10">
                    {articles.length > 0 ? (
                        articles.map((item, i) => (
                            <>
                                <ArticleItem item={item} key={item.title} />
                                <div
                                    key={i}
                                    className="hidden h-[550px] w-[1px] bg-gray nth-3n:hidden md:flex"
                                ></div>
                            </>
                        ))
                    ) : (
                        <div className="flex w-full flex-wrap">
                            {Array(6)
                                .fill(null)
                                .map((i) => (
                                    <Skeleton
                                        key={i}
                                        className="h-[200px] w-[100px]"
                                    />
                                ))}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export function ArticleItem({ item, className = "" }) {
    const [imgLoaded, setImgLoaded] = useState(false);
    const date = formatDateMonth(item.publish_date);
    const articleTag = item.article_tags[0];
    const setImgLoad = (e) => {
        setImgLoaded(true);
    };

    return (
        <OpacityWrapper className="group relative my-10 flex flex-col items-center px-4 text-center hover:scale-[1.01] md:h-[550px] md:w-1/4 md:px-0 md:pb-20">
            <Link
                to={`/news/${item.uri}-${item.id}`}
                className="flex h-full max-w-[400px] flex-col justify-between"
            >
                <div className="flex flex-col">
                    <div className="h-[200px] w-full object-cover">
                        {item.image_url ? (
                            <>
                                <img
                                    src={item.image_url}
                                    className="h-full w-full object-cover"
                                    onLoad={setImgLoad}
                                    loading="lazy"
                                />
                                {!imgLoaded && (
                                    <Skeleton className="h-[200px] w-full" />
                                )}
                            </>
                        ) : (
                            <img src={staticTree} className="w-full" />
                        )}
                    </div>
                    <div className="mt-4 line-clamp-3 text-ellipsis text-lg font-extrabold">
                        {item.title}
                    </div>
                    <div className="mt-2 line-clamp-4 text-ellipsis">
                        {item.summary}
                    </div>
                </div>
                <div className="mt-6 flex flex-col items-center justify-between md:mt-0 lg:flex-row">
                    <div className="mb-4 bg-black/[3%] py-[2px] text-sm uppercase text-[darkgray] lg:mb-0 lg:w-1/2">
                        {date}
                    </div>
                    <div className={tagStyle + " lg:w-1/2"}>
                        {articleTag ? tagsList.type[articleTag] : "read more"}
                    </div>
                </div>
            </Link>
        </OpacityWrapper>
    );
}

export const tagStyle =
    "md:group-hover:bg-green/75 tracking-wide md:bg-green text-green md:text-white md:text-xs font-bold self-center px-4 py-1 uppercase";
