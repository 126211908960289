import React, { useEffect } from "react";
import { LoadingAnimation } from "../LoadingAnimation";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

const AuthCallback = () => {
    const { handleRedirectCallback } = useAuth0();
    const navigate = useNavigate();

    useEffect(() => {
        const processCallback = async () => {
            try {
                const result = await handleRedirectCallback();
                const { appState } = result;

                // Redirect to the desired page
                navigate(appState?.returnTo || "/");
            } catch (error) {
                console.error("Error handling redirect callback:", error);
            }
        };

        processCallback();
    }, [handleRedirectCallback, navigate]);

    return (
        <div className={"h-auto w-full mx-2"}>
            <LoadingAnimation />
        </div>
    );
};

export default AuthCallback;
