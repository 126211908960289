import { Link } from "react-router-dom";
import { routesFlat } from "@routes";

const rowItem = "md:mr-20 ml-4 md:ml-0 mb-4";

export default function Legal() {
  return (
      <div className="flex flex-col text-sm font-bold">
        <Link to={routesFlat.privacy} className={rowItem}>
          Privacy Notice
        </Link>
        <Link to={routesFlat.terms} className={rowItem}>
          Terms of Use
        </Link>
      </div>
  );
}
