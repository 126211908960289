export function FullWidthSubHeader({children}) {
  return (
    <div className="md:mb-0 w-full md:w-overlines md:-mx-4">
      <div className={fullWidthSubHeader + " py-6 text-2xl"}>{children}</div>
    </div>
  )
}

export function FullWidthHeader({children}) {
  return (
    <div className="md:mb-0 w-full md:w-full md:-mx-4">
      <div className={fullWidthSubHeader + " py-6 md:text-4xl text-3xl"}>{children}</div>
    </div>
  )
}

const fullWidthSubHeader =
  "flex justify-center font-extrabold border-y-[1px] border-black/[10%] bg-white";
