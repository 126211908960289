export const header = {
    title: "Tree-Level Data",
    desc: "Map individual trees at local to national scales",
    img: "https://ctrees-website.s3.amazonaws.com/website_images/product/treelevel_pg.jpg",
  };
  
  // for each feature, add as an object item with title and desc attr inside of featsAndBenefits array
  export const featsAndBenefits = [
    {
      title: "Trees inside & outside forests",
      desc: "Map all trees, whether they are inside forests or outside forests, such as in savannas, urban, and altered landscapes.",
    },
    {
      title: "From location to structure",
      desc: "Access accurate information on trees’ location, crown size, height, and estimated carbon content.",
    },
    {
      title: "Demography and dynamics",
      desc: "Use numbers, size distribution, and spatial patterns of trees across landscapes to study disturbance and recovery processes, growth patterns, and ecosystem dynamics.",
    },
    {
      title: "Active fire management",
      desc: "Use tree-level data to identify areas for wildfire fuel reduction.",
    },
    {
      title: "Carbon data",
      desc: "Plan nature-based climate solutions with precise information on tree-level carbon.",
    },
    {
      title: "Available now",
      desc: "Tree-level data is available by license from CTrees and its scientific partners.",
    }
  ];
  
  // for each paragraph in methodology description, add as string item inside of method array
  export const desc = ["CTrees produces tree-level data at local, sub-national, and national levels using very high resolution (less than 1 meter) imagery from airborne surveys and commercial satellite data to map individual trees and estimate their structure. Our research team has developed an advanced deep-learning AI model trained by a large selection of sample data collected mostly by visual interpretation of imagery. The methodologies have been tested over different landscapes and tree structures and have been published in peer-reviewed journals [1-4]."
            , "The products are made using CTrees’ dedicated AI model and three novel techniques: (1) tree cover segmentation, where each pixel is classified as part of either a tree inside forests or outside in open and non-forest landscapes; (2) tree instance segmentation, where each individual tree is delineated accurately and receives a unique identification code and estimates of crown size; and (3) tree height estimation, where the AI model trained by airborne lidar data predicts the tree canopy height at the pixel level and for individual trees."
            , "CTrees tree-level products include a map of individual trees and their structural attributes across the continental US in 2020 using the US Department of Agriculture’s NAIP aerial images (< 60 centimeter) [5]. Additional tree-level products available from our partners at the University of Copenhagen include maps of continental Europe [6] and Africa [7], both produced using PlanetScope imagery."
          ];
  // for each numbered reference under methodology, add as string item inside of superscript array
  export const superscript = ["[1] Wagner, F. H. et al, (2019) Using the U‐net convolutional network to map forest types and disturbance in the Atlantic rainforest with very high resolution images. Remote Sens Ecol Conserv. https://doi:10.1002/rse2.111."
          , "[2] Wagner, F. H. et al, (2020) Mapping Atlantic rainforest degradation and regeneration history with indicator species using convolutional network. PloS one, 15(2), e0229448."
          , "[3] Wagner, F.H.; Hirye, M.C.M. (2020) Tree Cover for the Year 2010 of the Metropolitan Region of São Paulo, Brazil. Data, 4, 145. https://doi.org/10.3390/data4040145."
          , "[4] Wagner, F. H. et al, (2020) U-Net-Id, an Instance Segmentation Model for Building Extraction from Satellite Images—Case Study in the Joanópolis City, Brazil. Remote Sensing, 12(10), 1544."
          , "[5] Wagner, F. H. et al. (2024). Sub-Meter Tree Height Mapping of California using Aerial Images and LiDAR-Informed U-Net Model. Remote Sensing of Environment. 305. https://doi.org/10.1016/j.rse.2024.11409."
          , "[6] Liu, S., Brandt, M., & Fensholt, R. (2023). The overlooked contribution of trees outside forests to tree cover and woody biomass across Europe."
          , "[7] Reiner, F., Brandt, M., & Fensholt, R. (2023). More than one quarter of Africa’s tree cover is found outside areas previously classified as forest. Nature Communications, 14(1), 2258."
        ];