export default function Terms() {
  return (
    <div className="px-4 pb-10 md:p-10 flex justify-center mt-[80px]">
      <div className="max-w-[1200px]">
        <div className="mb-10">
        <div className="font-bold text-2xl text-center">Terms of Use</div>
        </div>
        <div>
          <p>
            <strong>Last updated: November 1, 2022</strong>
          </p>
          <p>
            Welcome! CTREES (&ldquo;<u>CTrees</u> &ldquo;<u>we</u>,&rdquo;
            &ldquo;us,&rdquo; or &ldquo;<u>our</u>&rdquo;) invites you to access
            and use our website located at https://ctrees.org/ (the &ldquo;
            <u>Website</u>&rdquo;), subject to the following terms and
            conditions (the &ldquo;<u>Terms of Use</u>
            &rdquo;).&nbsp;BY ACCESSING AND/OR USING OUR WEBSITE, YOU
            ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTOOD, AND AGREE TO BE LEGALLY
            BOUND BY THESE TERMS OF USE AND OUR PRIVACY POLICY (THE &ldquo;
            <u>PRIVACY POLICY</u>
            &rdquo;), WHICH IS HEREBY INCORPORATED INTO THESE TERMS OF USE AND
            MADE A PART HEREOF BY REFERENCE (COLLECTIVELY, THE &ldquo;
            <u>AGREEMENT</u>&rdquo;). IF YOU DO NOT AGREE TO ANY OF THE TERMS IN
            THE AGREEMENT, YOU MAY NOT ACCESS OR USE THE WEBSITE.{" "}
          </p>

          <p>
            We may change these Terms of Use from time to time with or without
            notice to you. If we modify these Terms of Use, we will post the
            modification on the Website. Please check these Terms of Use
            periodically for changes. Your continued use of the Website
            following the posting of the modified Terms of Use will mean you
            accept those changes.{" "}
          </p>

          <p>
            If you are accessing and using the Website on behalf of a legal
            entity, you represent that you have the authority to bind such
            entity to this Agreement, in which case the terms &ldquo;you&rdquo;
            or &ldquo;your&rdquo; refers to such entity, in addition to you in
            your individual capacity.
          </p>

          <p>
            From time to time we may offer fee-based products and services,
            including certain data (&ldquo;<u>Paid Products</u>
            &rdquo;). We provide access to and use of our Paid Products pursuant
            to commercial agreements, associated with the applicable Paid
            Products made available to you at the time of purchase (the
            Commercial
            <u> Agreement</u>&rdquo;). If there is a conflict between these
            Terms of Use and terms and conditions of the applicable Commercial
            Agreement associated with the Paid Product you are purchasing, the
            terms and conditions of the Commercial Agreement will take
            precedence with respect to the use of or access to such Paid
            Products.
          </p>

          <p>
            Capitalized terms not defined in these Terms of Use shall have the
            meaning set forth in our Privacy Policy.
          </p>

          <p>
            <strong>
              <span>
                THE SECTIONS BELOW TITLED &ldquo;BINDING ARBITRATION&rdquo; AND
                &ldquo;CLASS ACTION WAIVER&rdquo; CONTAIN A BINDING ARBITRATION
                AGREEMENT AND CLASS ACTION WAIVER. THEY AFFECT YOUR LEGAL
                RIGHTS. &nbsp;PLEASE READ THEM.
              </span>
            </strong>
          </p>

          <p>
            <u>
              <strong>
                <span>1. ACCESS AND USE</span>
              </strong>
            </u>
          </p>

          <p>
            <u>
              <span>Eligibility</span>
            </u>
            . The Website is available only to individuals aged 13 years or
            older, and only available in the United States. If you are 13 or
            older, but under the age of majority in your jurisdiction, you
            should review these Terms of Use with your parent or guardian to
            make sure that you and your parent or guardian understand it. If you
            are under the age of 13, you may not use the Website. We reserve the
            right, in our sole and absolute discretion, to deny you access to
            the Website, or any portion thereof, including by closing or
            disconnecting your account, without notice and without reason.
          </p>

          <p>
            <u>
              <strong>
                <span>2. COMMUNITY GUIDELINES</span>
              </strong>
            </u>
          </p>

          <p>
            By accessing and/or using the Website you hereby agree to comply
            with the following guidelines:&nbsp;{" "}
          </p>

          <ul>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not use the Website for any unlawful purpose;
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not access or use the Website to collect any
                      market research for a competing businesses;
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not impersonate any person or entity or falsely
                      state or otherwise misrepresent your affiliation with a
                      person or entity;{" "}
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not decompile, reverse engineer, or disassemble
                      any software or other data or processes accessible through
                      the Website;
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not cover, obscure, block, or in any way
                      interfere with any advertisements and/or safety features
                      on the Website;&nbsp;&nbsp;{" "}
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not circumvent, remove, alter, deactivate,
                      degrade, or thwart any of the protections in the Website;
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not use automated means, including spiders,
                      robots, crawlers, data mining tools, or the like to
                      download or scrape data from the Website, directly or
                      indirectly, except for Internet search engines (e.g.,
                      Google) and non-commercial public archives (e.g.,
                      archive.org) that comply with our robots.txt file;
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not take any action that imposes or may impose
                      (in our sole discretion) an unreasonable or
                      disproportionately large load on our technical
                      infrastructure; and
                    </span>
                  </span>
                </span>
              </span>
            </li>
            <li>
              <span>
                <span>
                  <span>
                    <span>
                      You will not interfere with or attempt to interrupt the
                      proper operation of the Website through the use of any
                      virus, device, information collection or transmission
                      mechanism, software or routine, or access or attempt to
                      gain access to any data, files, or passwords related to
                      the Website through hacking, password or data mining, or
                      any other means.&nbsp;{" "}
                    </span>
                  </span>
                </span>
              </span>
            </li>
          </ul>

          <p>
            We reserve the right, in our sole and absolute discretion, to deny
            you (or any device) access to the Website, or any portion thereof,
            without notice.
          </p>

          <p>
            <u>
              <strong>
                <span>
                  <span>3. INTELLECTUAL PROPERTY</span>
                </span>
              </strong>
            </u>
          </p>

          <p>
            The Website and all information and materials available on the
            Website are protected by copyright, trademark, and other laws of the
            United States and foreign countries. Except as expressly provided in
            these Terms of Use, CTrees and its licensors exclusively own all
            right, title, and interest in and to the Website, including all
            associated intellectual property rights. You will not remove, alter,
            or obscure any copyright, trademark, service mark, or other
            proprietary rights notices incorporated in or accompanying any
            Website.
          </p>

          <p>
            You may view all content on the Website (the &ldquo;
            <u>Content</u>&rdquo;) for your own personal or internal business
            use and not for any other use, including any commercial use, without
            the prior written consent of CTrees. We, and our licensors, retain
            all right, title, and interest, including all intellectual property
            rights, in and to the Content. You may not sell, transfer, assign,
            license, sublicense, or modify the Content or reproduce, display,
            publicly perform, make a derivative version of, distribute, or
            otherwise use the Content in any way for any commercial
            purpose.&nbsp;{" "}
          </p>

          <p>
            If you violate any part of this Agreement, your permission to access
            the Website automatically terminates and you must immediately
            destroy any copies you have made of any Website.
          </p>

          <p>
            The trademarks, service marks, and logos of CTrees (the &ldquo;
            <u>CTrees Trademarks</u>&rdquo;) used and displayed on the Website
            are registered and unregistered trademarks or service marks of
            CTrees. Other company, product, and service names located on the
            Website may be trademarks or service marks owned by others (the
            &ldquo;Third-Party Trademarks,&rdquo; and, collectively with CTrees
            Trademarks, the &ldquo;Trademarks&rdquo;). Nothing on the Website
            should be construed as granting, by implication, estoppel, or
            otherwise, any license or right to use the Trademarks, without our
            prior written permission specific for each such use. Use of the
            Trademarks as part of a link to or from any website is prohibited
            unless establishment of such a link is approved in advance by us in
            writing. All goodwill generated from the use of CTrees Trademarks
            inures to our benefit.
          </p>

          <p>
            Elements of the Website are protected by trade dress, trademark,
            unfair competition, and other state and federal laws and may not be
            copied or imitated in whole or in part, by any means, including, but
            not limited to, the use of framing or mirrors. None of the Content
            may be retransmitted without attribution citing CTrees, in each and
            every instance.
          </p>

          <p>
            <u>
              <strong>
                <span>
                  <span>4. FEEDBACK</span>
                </span>
              </strong>
            </u>
          </p>

          <p>
            We welcome and encourage you to provide feedback, comments, and
            suggestions for improvements to the Website and our products and
            services (&ldquo;<u>Feedback</u>&rdquo;). Although we encourage you
            to e-mail us, or use our contact form, we do not want you to, and
            you should not, provide us any content that contains confidential
            information. With respect to any Feedback you provide, we shall be
            free to use and disclose any ideas, concepts, know-how, techniques,
            or other materials contained in your Feedback for any purpose
            whatsoever, including, but not limited to, the development,
            production and marketing of products and services that incorporate
            such information, without compensation or attribution to you.
          </p>

          <p>
            <u>
              <strong>
                <span>
                  <span>5. DOWNLOADED CONTENT AND ATTRIBUTION</span>
                </span>
              </strong>
            </u>
          </p>

          <p>
            Our Website contains data relating to carbon in trees and forests in
            various geographic areas. Some of this information is available for
            you to download from our Website. If you would like to download data
            from our Website, you must request the ability to do so by engaging
            the [&ldquo;Request a Download&rdquo;] link or using the contact
            form on our Website. Approved requests will receive a link through
            which you may download such information. By downloading any data, or
            information from our Website (&ldquo;<u>Downloaded Data</u>&rdquo;),
            you agree to the following: (i) Downloaded Data is licensed, not
            sold, to you for your personal or internal use only under the terms
            and conditions of this Agreement.{" "}
            <span lang="EN">
              CTrees reserves all rights in and to the Downloaded Data not
              expressly granted to you in this Agreement; (ii) you shall retain
              all branding, trademark and copyright notices, attributions, and
              identification of authors that appear on the Downloaded Data
              delivered to you; (iii) to the extent you would like to use the
              Downloaded Data for external purposes, or excerpt portions of the
              Downloaded Data for external purposes, including without
              limitation, reports or published materials, you shall display the
              appropriate copyright notice and cite CTrees as a source.
            </span>
          </p>

          <p>
            Further, you agree you shall not: (i) remove, obscure or modify any
            proprietary marking or restrictive legends placed on the Downloaded
            Data, copyright or other notices, trademark, source identifiers, or
            other designations; (ii) sell or commercialize the Downloaded Data
            or charge others a fee to view the Downloaded Data, or sell
            advertising specifically against it, without our prior written
            consent; or (iii) directly or indirectly, to create, implement, or
            enhance, engage or participate in any activity or course of action
            that could diminish or tarnish the image or reputation of the
            Downloaded Content or CTrees, including fraud or cause confusion as
            to the ownership of the Downloaded Content, including by means of
            misrepresentation.
          </p>

          <p>
            <u>
              <strong>
                <span>6. DISCLAIMERS AND LIMITATION OF LIABILITY</span>
              </strong>
            </u>
          </p>

          <p>
            THE WEBSITE, AND INFORMATION AND CONTENT PROVIDED ON THE WEBSITE,
            INCLUDING DOWNLOADED DATA ARE PROVIDED ON AN &ldquo;AS IS&rdquo; AND
            &ldquo;AS AVAILABLE&rdquo; BASIS, AND NEITHER CTREES NOR ITS
            LICENSORS MAKE ANY WARRANTIES WITH RESPECT TO THE SAME OR OTHERWISE
            IN CONNECTION WITH THIS AGREEMENT, AND CTREES HEREBY DISCLAIMS ANY
            AND ALL EXPRESS, IMPLIED, OR STATUTORY WARRANTIES, INCLUDING,
            WITHOUT LIMITATION, ANY WARRANTIES OF NON-INFRINGEMENT,
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AVAILABILITY,
            ERROR-FREE OR UNINTERRUPTED OPERATION, AND ANY WARRANTIES ARISING
            FROM A COURSE OF DEALING, COURSE OF PERFORMANCE, OR USAGE OF TRADE.
            WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, CTREES MAKES NO
            WARRANTIES REGARDING THE ACCURACY, COMPLETENESS, OR CURRENTNESS OF
            ANY CONTENT AVAILABLE ON THE WEBSITE, OR ANY DOWNLOADED DATA. CTREES
            PROVIDES ALL CONTENT AND DOWNLOADED DATA FOR INFORMATIONAL PURPOSES
            AND YOU MUST EXERCISE YOUR OWN JUDGEMENT WITH RESPECT TO YOUR USE OF
            SUCH CONTENT OR DOWNLOADED DATA. TO THE EXTENT THAT CTREES AND ITS
            SUPPLIERS MAY NOT AS A MATTER OF APPLICABLE LAW DISCLAIM ANY IMPLIED
            WARRANTY, THE SCOPE AND DURATION OF SUCH WARRANTY WILL BE THE
            MINIMUM PERMITTED UNDER SUCH LAW.{" "}
          </p>

          <p>
            IN CONNECTION WITH ANY WARRANTY, CONTRACT, OR COMMON LAW TORT
            CLAIMS: (I) WE SHALL NOT BE LIABLE FOR ANY INCIDENTAL OR
            CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM LOST
            DATA OR BUSINESS INTERRUPTION RESULTING FROM THE USE OR INABILITY TO
            ACCESS AND USE THE WEBSITE,&nbsp; THE CONTENT OR ANY DOWNLOADED
            DATA, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
            DAMAGES; AND (II) ANY DIRECT DAMAGES THAT YOU MAY SUFFER AS A RESULT
            OF YOUR USE OF THE WEBSITE, THE CONTENT OR ANY DOWNLOADED DATA SHALL
            BE LIMITED TO FIFTY DOLLARS ($50).
          </p>

          <p>
            SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES.
            THEREFORE, SOME OF THE ABOVE LIMITATIONS ON WARRANTIES IN THIS
            SECTION MAY NOT APPLY TO YOU.
          </p>

          <p>
            NOTHING IN THESE TERMS OF USE SHALL AFFECT ANY NON-WAIVABLE
            STATUTORY RIGHTS THAT APPLY TO YOU.{" "}
          </p>

          <p>
            THE WEBSITE, THE CONTENT AND THE DOWNLOADED DATA MAY CONTAIN
            TECHNICAL INACCURACIES OR TYPOGRAPHICAL ERRORS OR OMISSIONS. UNLESS
            REQUIRED BY APPLICABLE LAWS, WE ARE NOT RESPONSIBLE FOR ANY SUCH
            TYPOGRAPHICAL OR TECHNICAL ERRORS LISTED ON ANY OF THE WEBSITE, THE
            CONTENT AND ANY DOWNLOADED DATA.&nbsp; WE RESERVE THE RIGHT TO MAKE
            CHANGES, CORRECTIONS, AND/OR IMPROVEMENTS TO THE WEBSITE, THE
            CONTENT, AND ANY DATA PREVIOUSLY DOWNLOADED BY YOU, AT ANY TIME
            WITHOUT NOTICE.
          </p>

          <p>
            <u>
              <strong>
                <span>7. EXTERNAL WEBSITE</span>
              </strong>
            </u>
          </p>

          <p>
            The Website may contain links to third-party websites (&ldquo;
            <u>External Website</u>&rdquo;), but we do not endorse and are not
            responsible for the maintenance or content of any linked External
            Website. Please refer to the terms of use and privacy policies of
            the External Website for more information.{" "}
          </p>

          <p>
            <u>
              <strong>8. INDEMNIFICATION</strong>
            </u>
          </p>

          <p>
            You will indemnify, defend, and hold CTrees and its shareholders,
            members, officers, directors, employees, agents, and representatives
            (collectively, &ldquo;<u>CTrees Indemnitees</u>&rdquo;) harmless
            from and against any and all damages, liabilities, losses, costs,
            and expenses, including reasonable attorney&rsquo;s fees
            (collectively, &ldquo;<u>Losses</u>&rdquo;) incurred by any CTrees
            Indemnitee in connection with a third-party claim, action, or
            proceeding (each, a &ldquo;<u>Claim</u>&rdquo;) arising from (i)
            your breach of this Agreement; (ii) your misuse of the Website,
            Content and/or the Downloaded Data; or (iii) your violation of any
            third-party right, including without limitation any intellectual
            property or privacy right; <em>provided</em>, <em>however</em>, that
            the foregoing obligations shall be subject to our: (i) promptly
            notifying you of the Claim; (ii) providing you, at your expense,
            with reasonable cooperation in the defense of the Claim; and (iii)
            providing you with sole control over the defense and negotiations
            for a settlement or compromise.
          </p>

          <p>
            <u>
              <strong>
                <span>9. TERMINATION</span>
              </strong>
            </u>
          </p>

          <p>
            We reserve the right, in our sole discretion, to restrict, suspend,
            or terminate the Agreement and/or your access to all or any part of
            the Website, Content, and the Downloaded Data, at any time and for
            any reason without prior notice or liability. We reserve the right
            to change, suspend, or discontinue all or any part of the Website,
            Content and/or the Downloaded Data at any time without prior notice
            or liability.&nbsp;{" "}
          </p>

          <p>
            <u>
              <strong>
                <span>10. BINDING ARBITRATION</span>
              </strong>
            </u>
          </p>

          <p>
            In the event of a dispute arising under or relating to this
            Agreement, the Website, Content and the Downloaded Data (each, a
            &ldquo;
            <u>Dispute</u>&rdquo;), such dispute will be finally and exclusively
            resolved by binding arbitration governed by the Federal Arbitration
            Act (&ldquo;<u>FAA</u>&rdquo;). NEITHER PARTY SHALL HAVE THE RIGHT
            TO LITIGATE SUCH CLAIM IN COURT OR TO HAVE A JURY TRIAL, EXCEPT
            EITHER PARTY MAY BRING ITS CLAIM IN ITS LOCAL SMALL CLAIMS COURT, IF
            PERMITTED BY THAT SMALL CLAIMS COURT RULES AND IF WITHIN SUCH
            COURT&rsquo;S JURISDICTION.&nbsp; ARBITRATION IS DIFFERENT FROM
            COURT, AND DISCOVERY AND APPEAL RIGHTS MAY ALSO BE LIMITED IN
            ARBITRATION. All disputes will be resolved before a neutral
            arbitrator selected jointly by the parties, whose decision will be
            final, except for a limited right of appeal under the FAA. The
            arbitration shall be commenced and conducted by JAMS pursuant to its
            then current Comprehensive Arbitration Rules and Procedures and in
            accordance with the Expedited Procedures in those rules, or, where
            appropriate, pursuant to JAMS&rsquo; Streamlined Arbitration Rules
            and Procedures. All applicable JAMS&rsquo; rules and procedures are
            available at the JAMS website{" "}
            <a href="http://www.jamsadr.com">
              <span>www.jamsadr.com</span>
            </a>
            . Each party will be responsible for paying any JAMS filing,
            administrative, and arbitrator fees in accordance with JAMS rules.
            Judgment on the arbitrator&rsquo;s award may be entered in any court
            having jurisdiction. This clause shall not preclude parties from
            seeking provisional remedies in aid of arbitration from a court of
            appropriate jurisdiction. The arbitration may be conducted in
            person, through the submission of documents, by phone, or online. If
            conducted in person, the arbitration shall take place in the United
            States&rsquo; county where you reside. The parties may litigate in
            court to compel arbitration, to stay a proceeding pending
            arbitration, or to confirm, modify, vacate, or enter judgment on the
            award entered by the arbitrator. The parties shall cooperate in good
            faith in the voluntary and informal exchange of all non-privileged
            documents and other information (including electronically stored
            information) relevant to the Dispute immediately after commencement
            of the arbitration. As set forth in the &ldquo;Equitable
            Relief&rdquo; Section<u> </u>below, nothing in this Agreement will
            prevent us from seeking injunctive relief in any court of competent
            jurisdiction as necessary to protect our proprietary interests.
          </p>

          <p>
            <u>
              <strong>
                <span>11. CLASS ACTION WAIVER</span>
              </strong>
            </u>
          </p>

          <p>
            You agree that any arbitration or proceeding shall be limited to the
            Dispute between us and you individually. To the full extent
            permitted by law, (i) no arbitration or proceeding shall be joined
            with any other; (ii) there is no right or authority for any Dispute
            to be arbitrated or resolved on a class action-basis or to utilize
            class action procedures; and (iii) there is no right or authority
            for any Dispute to be brought in a purported representative capacity
            on behalf of the general public or any other persons. YOU AGREE THAT
            YOU MAY BRING CLAIMS AGAINST US ONLY IN YOUR INDIVIDUAL CAPACITY AND
            NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
            REPRESENTATIVE PROCEEDING.
          </p>

          <p>
            <u>
              <strong>
                <span>12. EQUITABLE&nbsp;RELIEF</span>
              </strong>
            </u>
          </p>

          <p>
            You acknowledge and agree that in the event of a breach or
            threatened violation of our intellectual property rights and
            confidential and proprietary information by you, we will suffer
            irreparable harm and will therefore be entitled to injunctive relief
            to enforce this Agreement. We may, without waiving any other
            remedies under this Agreement, seek from any court having
            jurisdiction any interim, equitable, provisional, or injunctive
            relief that is necessary to protect our rights and property pending
            the outcome of the arbitration referenced above. You hereby
            irrevocably and unconditionally consent to the personal and subject
            matter jurisdiction of the federal and state courts in the State of
            California for purposes of any such action by us.
          </p>

          <p>
            <u>
              <strong>
                <span>13. MISCELLANEOUS</span>
              </strong>
            </u>
          </p>

          <p>
            If the Agreement is terminated in accordance with the
            &ldquo;Termination&rdquo; Section above, such termination shall not
            affect the validity of the following provisions of this Agreement,
            which shall remain in full force and effect: &ldquo;Intellectual
            Property,&rdquo; &ldquo;Feedback,&rdquo; &ldquo;Disclaimers and
            Limitation of Liability,&rdquo; &ldquo;Indemnification,&rdquo;
            &ldquo;Termination,&rdquo; &ldquo;Binding Arbitration,&rdquo;
            &ldquo;Class Action Waiver,&rdquo; and &ldquo;Miscellaneous.&rdquo;
            These Terms of Use constitute the entire agreement between the
            parties, and supersedes all prior and contemporaneous written or
            oral agreements, proposals or communications with respect to the
            subject matter herein between you and CTrees. The section headings
            in these Terms of Use are for convenience only and must not be given
            any legal import. In the event that any portion of these Terms of
            Use is held to be invalid or unenforceable, then such portion shall
            be construed in accordance with the applicable law as nearly as
            possible to reflect the original intentions of the parties, and the
            remainder of these Terms of Use shall remain in full force and
            effect. You may not assign these Terms of Use. No waiver shall be
            effective unless in writing. Neither the course of conduct between
            parties nor trade practice shall act to modify any provision of
            these Terms of Use. These Terms of Use shall be governed by and
            construed in accordance with the laws of the State of California.
            Except for proceedings commenced by CTrees to protect its
            intellectual property or confidential information which may be
            brought in any court of competent jurisdiction, the parties mutually
            agree that any and all disputes arising hereunder shall be resolved
            exclusively by state or federal courts located in the State of
            California and each party hereby irrevocably submits to the
            exclusive jurisdiction of such courts in any suits, actions, or
            proceedings arising out of or relating to this Agreement.{" "}
          </p>

          <p>
            <em>Copyright 2022 CTREES. All rights reserved.&nbsp;</em>{" "}
          </p>
        </div>
      </div>
    </div>
  );
}
