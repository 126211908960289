import clsx from "clsx";

export const logoBox = clsx(
  "h-full w-full overflow-hidden border-t-[1px] border-black/[10%] md:border-r-[1px]",
);
export const rowContainer = clsx("flex h-[200px] w-full md:h-auto md:w-1/2");
export const socialBox = clsx(
  "text-md flex h-full w-full items-center justify-center font-bold",
);
export const legalLink = clsx("ml-4 mt-6 flex flex-nowrap md:-ml-4 md:mt-2");
export const legalAndLogoCol = clsx(
  "flex h-full w-full flex-col justify-between border-r-[1px] border-t-[1px] border-black/[10%] md:border-t-0",
);
export const firstParentContainer = clsx(
  "md:text-md bg-bluegray relative bottom-0 flex h-[400px] w-full overflow-hidden border-t-[1px] border-black/[10%] text-lg md:h-[250px]",
);
export const secondParentContainer = clsx(
  "mb-4 flex flex-nowrap justify-around border-b-[1px] border-black/[10%] md:-mx-4 md:w-overlines",
);
export const thirdParentContainer = clsx(
  "flex w-full flex-col-reverse md:mx-4 md:flex-row md:px-4",
);
