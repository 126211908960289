import { OpacityWrapper } from "@components";
import { partners } from "@helpers/constants";

export default function Partners() {
  return (
    <OpacityWrapper className="w-full items-center flex flex-col md:flex-row justify-center md:h-[800px] border-t-[1px] border-black/[10%]">
      <div className="md:w-1/2 flex justify-center">
        <div className="max-w-[400px] text-center">
          <div className="font-bold text-4xl capitalize md:mb-4 md:mt-0 py-10 mb-16 md:border-none border-b-[1px] border-black/[10%]">our partners</div>
          <div className="text-xl px-4 md:px-0">
            We measure and report on forest carbon for organizations protecting
            and restoring forests globally.
          </div>
        </div>
      </div>
      <div className="flex flex-row h-full md:w-1/2 w-full">
        <div className={iconRow}>
          {iconsRow1.map((icon) => {
            const invert = icon?.includes("taskforce") ? " invert" : ""
            return (
              <div
                className={`pt-20 md:pt-0 ${invert}`}
                key={icon}
              >
                <img src={icon} className={iconStyle} loading="lazy" />
              </div>
            );
          })}
        </div>
        <div className={iconRow}>
          {iconsRow2.map((icon) => {
            return (
              <div className={"pt-20 md:pt-0"} key={icon}>
                <img src={icon} className={iconStyle} loading="lazy" />
              </div>
            );
          })}
        </div>
      </div>
    </OpacityWrapper>
  );
}

const iconStyle = "w-auto h-[50px] md:h-[60px]";
const iconRow =
  "flex flex-col items-center justify-around h-full md:border-l-[1px] border-black/[10%] pb-20 md:py-20 w-full";

const iconsRow1 = [
  partners.ageos,
  partners.cool,
  partners.crosswalk,
  partners.taskforce,
  partners.cll
];
const iconsRow2 = [partners.terra, partners.trace, partners.vp, partners.aso, partners.fs];
