import { routesFlat } from "@routes";
import { logos } from "@helpers/constants";
import lines from "@assets/lines.jpg";
import { Legal } from "./components";
import * as styles from "./styles";

export default function Footer() {
  return (
    <div className={styles.firstParentContainer}>
      <div className={styles.secondParentContainer}>
        <div className={styles.thirdParentContainer}>
          <div className={styles.rowContainer}>
            <div className={styles.legalAndLogoCol}>
              <a href={routesFlat.landing} className={styles.legalLink}>
                <img
                  className="mb-0 h-[40px] md:hidden"
                  src={logos.black}
                  alt={lineBlockAltText}
                />
                <img
                  className="hidden max-h-[70px] md:flex"
                  src={logos.blackWithText}
                  alt={footerLogoAltText}
                />
              </a>
              <Legal />
            </div>

            <div className={styles.logoBox}>
              <img
                className={"w-full md:h-full"}
                src={lines}
                alt={lineBlockAltText}
              />{" "}
            </div>
          </div>

          <div className={styles.rowContainer}>
            <div
              className={
                styles.socialBox + " border-r-[1px] border-black/[10%]"
              }
            >
              <a
                target="_blank"
                rel="noreferrer"
                href={routesFlat.linkedin}
                className={""}
              >
                LinkedIn
              </a>
            </div>

            <div className={styles.socialBox}>
              <a
                target="_blank"
                rel="noreferrer"
                href={routesFlat.youtube}
                className={""}
              >
                YouTube
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const lineBlockAltText = "black blob with leaf lines through it";
const footerLogoAltText =
  "black blob with leaf lines through it and ctrees text to the right";
