export default function Privacy() {
    return (
        <div className="mt-[80px] flex justify-center pb-10 md:p-10">
            <div className="flex w-full max-w-[1200px] flex-col justify-center break-words">
                <div className="mb-10">
                    <div className="text-center text-2xl font-bold">
                        CTrees Privacy Policy
                    </div>
                </div>
                <div className="mx-4 flex flex-col justify-center">
                    <p className="c13 c7">
                        <span className="c3">Last updated: October 10, 2024</span>
                    </p>
                    <p className="c13 c7 c15">
                        <span >At CTrees (&ldquo;</span>
                        <span className="font-bold">CTrees</span>
                        <span >&rdquo;, &ldquo;</span>
                        <span className="font-bold">we</span>
                        <span >&rdquo;, &ldquo;</span>
                        <span className="font-bold">our</span>
                        <span> &rdquo;, &nbsp;or &ldquo;</span>
                        <span className="font-bold">us</span>
                        <span >
                            &rdquo;), the privacy of our subscribers is of the
                            utmost importance. Thank you for taking the time to
                            read our privacy policy (the &ldquo;
                        </span>
                        <span className="font-bold">Privacy Policy</span>
                        <span className="c15">
                            &rdquo;). CTrees is a not-for-profit organization
                            that tracks forest carbon around the world.
                            Subscribers to your services can access and download
                            data and other information associated with our
                            tracking activities{" "}
                        </span>
                        <span className="c15 c27">(collectively, the &ldquo;</span>
                        <span className="font-bold c27">Services</span>
                        <span className="c15 c27">&rdquo;).</span>
                        <span className="c15">&nbsp;</span>
                        <span className="c15 c27">
                            This Privacy Policy sets out how we collect, use,
                            process, store, and disclose your personal
                            information on{" "}
                        </span>
                        <span className="c15 c29">
                            <a
                                className="c32"
                                href="https://www.google.com/url?q=http://www.ctrees.org&amp;sa=D&amp;source=editors&amp;ust=1728597283467492&amp;usg=AOvVaw1LiGdtI9HbVumHkR2G9koz"
                            >
                                www.ctrees.org
                            </a>
                        </span>
                        <span className="c15 c27">&nbsp;(the &ldquo;</span>
                        <span className="font-bold c27">Website</span>
                        <span className="c16 c15 c18">
                            &rdquo;) and the Services. &nbsp;{" "}
                        </span>
                    </p>
                    <p className="c13 c7 c15">
                        <span className="c16 c15 c18">
                            By accessing and using our Services you freely and
                            expressly consent to the collection, use,
                            processing, storage, and disclosure of your personal
                            information as set out in this Privacy Policy and
                            our Terms of Service.
                        </span>
                    </p>
                    <ol className="c5 lst-kix_v5hidn4vm953-0 start" start="1">
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="c16 c15 c18">
                                When We Collect Your Personal Information
                            </span>
                        </li>
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="c16 c15 c18">
                                Personal Information We Collect{" "}
                            </span>
                        </li>
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="c16 c15 c18">
                                How We Use Your Information
                            </span>
                        </li>
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="c16 c15 c18">
                                How We Disclose Your Personal Information
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">Your Privacy Choices</span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Use of Cookies and Tracking Technologies
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                U.S. State-Specific Disclosure
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                UK/EEA Residents&rsquo; Notice
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">Security</span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">Retention</span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Third-Party Links On Our Website
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Changes To This Privacy Policy
                            </span>
                        </li>
                        <li className="c20 c23 c19 c26 list-decimal">
                            <span className="c15">Contact Information</span>
                        </li>
                    </ol>
                    <ol className="c5 lst-kix_6uzd85a855oh-0 start" start="1">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.41e18dmm8x0b"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    When We Collect Your Personal Information
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c7">
                        <span className="c3">
                            We may collect personal information that you provide
                            when you:
                        </span>
                    </p>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                    <ul className="c5 lst-kix_dgnda836raf5-0 start">
                        <li className="c6 list-decimal">
                            <span className="c3">Visit our Website.</span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c15">Register for our Services.</span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">Use our Services.</span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Sign up for our email newsletter.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c15">
                                Contact or otherwise correspond with us.
                            </span>
                        </li>
                    </ul>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="2">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.l3kiy6rvxgc3"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    Personal Information We Collect
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <h2 className="c28 c23 c21" id="h.x9fzlusv0ws6">
                        <span className="c12">Information you provide:</span>
                    </h2>
                    <ul className="c5 lst-kix_lb8xem4ko0s6-0 start">
                        <li className="c6 list-decimal">
                            <span className="font-bold">Account Creation.</span>
                            <span className="c15">
                                &nbsp;When you register to our Services (&ldquo;
                            </span>
                            <span className="font-bold">Subscriber(s)</span>
                            <span className="c3">
                                &rdquo;), we collect contact details such as
                                your name and email address.{" "}
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="font-bold">Correspondence.</span>
                            <span className="c3">
                                &nbsp;We may collect information from your
                                inquiries into our services, or how you
                                otherwise indicate you may be interested in our
                                services, which may include signing up for email
                                communications or providing us with your name,
                                work email, phone number, company name, position
                                title, website, social media links, and time
                                zone.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="font-bold">
                                Institutional Account Creation.
                            </span>
                            <span className="c3">
                                &nbsp;If you subscribe to CTrees in the context
                                of your employment, we may collect, in addition
                                to the &lsquo;Account Creation
                                Information&rsquo; detailed above, the company
                                name, work email, your position title, and the
                                payment information of your employer.{" "}
                            </span>
                        </li>
                    </ul>
                    <h2 className="c23 c21 c28" id="h.f2t2a4snflfn">
                        <span className="c12">
                            Information we automatically collect:
                        </span>
                    </h2>
                    <ul className="c5 lst-kix_fnv6l0au8skk-0 start">
                        <li className="c6 list-decimal">
                            <span className="font-bold">Search Query Data. </span>
                            <span className="c15">
                                We collect your search query data you enter into
                                our Services in order to{" "}
                            </span>
                            <span className="c15 c27">
                                improve the Services, to improve the results for
                                that Subscriber and improve performance with the
                                overall Services.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="font-bold">Metadata and Analytics.</span>
                            <span className="c3">
                                &nbsp;We may collect Metadata and analytics of
                                your use of our Services, including IP address,
                                device information, date/time of visits, new or
                                returning visits, products viewed, page response
                                times, URL clickstreams, how long you stay on
                                our pages, what you do on those pages.
                            </span>
                        </li>
                    </ul>
                    <p className="c7 c8">
                        <span className="c16 c15 c18"></span>
                    </p>
                    <p className="c20 c23">
                        <span className="c15">
                            We do not intentionally collect any personal
                            information from children under the age of 18. If
                            you believe we have obtained personal information
                            associated with children under the age of 18, please
                            contact us at info@ctrees.org and we will delete it.{" "}
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="3">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.10y5kr43nalc"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    How We Use Your Information{" "}
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c13 c7">
                        <span className="c3">
                            We use the personal information we collect for the
                            following purposes:
                        </span>
                    </p>
                    <p className="c7 c8 c13">
                        <span className="c3"></span>
                    </p>
                    <ul className="c5 lst-kix_nqekrkv83iz5-0 start">
                        <li className="c6 list-decimal">
                            <span className="c3">
                                To provide and operate our Services, including
                                facilitating account creation and managing user
                                accounts as well as providing our software or
                                services.
                            </span>
                        </li>
                        <li className="c13 c7 c19 list-decimal">
                            <span className="c3">
                                To improve our Services, for example, we use
                                your information to better our Services.
                            </span>
                        </li>
                        <li className="c13 c7 c19 list-decimal">
                            <span className="c3">
                                To identify usage trends and otherwise measure
                                all activities on the Services.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                For customer support, including via email.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                To communicate with you, including via our
                                newsletter or to request feedback.{" "}
                            </span>
                        </li>
                        <li className="c13 c7 c19 list-decimal">
                            <span className="c3">
                                To protect our Services and keep our Services
                                safe and securing, including fraud monitoring
                                and prevention.
                            </span>
                        </li>
                        <li className="c13 c7 c19 list-decimal">
                            <span className="c3">
                                For other business and legal purposes, such as
                                to collect amounts owing to us, and to maintain
                                business records.
                            </span>
                        </li>
                        <li className="c13 c7 c19 list-decimal">
                            <span className="c15">With your consent.</span>
                        </li>
                    </ul>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="4">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.15tf3iygq7ot"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    How We Disclose Your Personal Information
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c7">
                        <span className="c3">
                            We may disclose your information with your consent.
                            We may also disclose your information with our
                            corporate affiliates, as well as vendors or service
                            providers who:
                        </span>
                    </p>
                    <p className="c7 c8">
                        <span className="c16 c15 c18"></span>
                    </p>
                    <ul className="c5 lst-kix_1py38b61n0zz-0 start">
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Help us communicate with you, such as through
                                our email service provider.{" "}
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Manage and optimize our Website and Services,
                                such as through Google Analytics.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c3">
                                Provide cloud hosting, such as through Amazon
                                Web Services.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c16 c15 c18">
                                Provide analytics/measurement.
                            </span>
                        </li>
                        <li className="c6 list-decimal">
                            <span className="c16 c15 c18">
                                Help us provide customer support.{" "}
                            </span>
                        </li>
                    </ul>
                    <p className="c7 c8">
                        <span className="c16 c15 c18"></span>
                    </p>
                    <p className="c7">
                        <span className="c16 c15 c18">
                            In addition to the above, we may disclose your
                            information for the following purposes:
                        </span>
                    </p>
                    <p className="c7 c8">
                        <span className="c16 c15 c18"></span>
                    </p>
                    <ul className="c5 lst-kix_i1pthiv232ct-0 start">
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="font-bold c27">
                                Legal and Compliance Purposes.{" "}
                            </span>
                            <span className="c16 c15 c18">
                                We may share information with external parties
                                when complying with legal process (eg;
                                subpoena), to protect our intellectual property
                                and other legal rights, to prevent fraud or
                                imminent harm, and secure the use of our
                                Services. We reserve the right to disclose
                                information as required by law and when we
                                believe that disclosure is necessary to protect
                                our rights and/or comply with a law enforcement
                                order, such as a search warrant, judicial
                                proceeding, or court order. We may also retain
                                and use information as necessary to comply with
                                our legal obligations, resolve disputes, and
                                enforce our Terms of Service.
                            </span>
                        </li>
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="font-bold c27">Business Transactions</span>
                            <span className="c16 c15 c18">
                                . In the event that CTrees goes through a
                                business transaction, such as a merger,
                                acquisition by another company, or sale of all
                                or a portion of its assets, bankruptcy, or other
                                corporate change, including, without limitation,
                                during the course of any due diligence process,
                                subscriber information, including Personal
                                Information, will likely be among the assets
                                shared and transferred. Subscribers will be
                                notified via email and/or a prominent notice on
                                the Services of any completed change in
                                ownership or materially different uses of their
                                personal information. This Privacy Policy will
                                become binding upon the new owner of the
                                information until amended.
                            </span>
                        </li>
                        <li className="c13 c7 c19 c15 list-decimal">
                            <span className="font-bold c27">Anonymous Data. </span>
                            <span className="c16 c15 c18">
                                We may use and disclose anonymous or aggregated
                                information about our children&rsquo;s
                                subscribers to any external parties, including
                                current and potential business
                                partners/affiliates, &nbsp;investors, or the
                                public, or use it for research or analytics
                                purposes.
                            </span>
                        </li>
                    </ul>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="5">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.myrl6x29bxvv"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    Your Privacy Choices
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c7 c24">
                        <span className="c3">
                            CTrees provides you with the ability to exercise the
                            following choices with our use of your personal
                            information:
                        </span>
                    </p>
                    <p className="c7 c8 c24">
                        <span className="c3"></span>
                    </p>
                    <ul className="c5 lst-kix_hvlcm75noh13-0 start">
                        <li className="c9 list-decimal">
                            <span className="c3">
                                Access the personal information we maintain
                                about you.{" "}
                            </span>
                        </li>
                        <li className="c9 list-decimal">
                            <span className="c3">
                                Delete the personal information we maintain
                                about you.{" "}
                            </span>
                        </li>
                        <li className="c9 list-decimal">
                            <span className="c3">
                                Correct inaccurate personal information we
                                maintain about you
                            </span>
                        </li>
                        <li className="c9 list-decimal">
                            <span className="c3">
                                Opt out of certain uses of your personal
                                information, notably, you can unsubscribe to our
                                email list by clicking the unsubscribe link at
                                the bottom of marketing emails.
                            </span>
                        </li>
                    </ul>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                    <p className="c20">
                        <span className="c3">
                            You can exercise these rights by contacting us at
                            info@ctrees.org &nbsp;
                        </span>
                    </p>
                    <p className="c20">
                        <span className="c3">
                            If you would at any time like to review or change
                            the information in your account or terminate your
                            account, you can contact us using the contact
                            information provided. Upon your request to terminate
                            your account, we will deactivate or delete your
                            account and information from our active databases.
                            However, we may retain some information in our files
                            to prevent fraud, troubleshoot problems, assist with
                            any investigations, enforce our legal terms and/or
                            comply with applicable legal requirements.{" "}
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="6">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.8yv763uw0bkm"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    Use of Cookies and Tracking Technologies
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c7">
                        <span className="c15">
                            We may use cookies and similar tracking technologies
                            like web beacons and pixels (&ldquo;
                        </span>
                        <span className="font-bold">cookies</span>
                        <span className="c3">
                            &rdquo;) to access or store information for
                            functional and analytics purposes.{" "}
                        </span>
                    </p>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                    <p className="c7">
                        <span className="c3">
                            Most browsers let you remove or reject cookies. To
                            do this, follow the instructions in your browser
                            settings. Many browsers accept cookies by default
                            until you change your settings. Please note that
                            because this opt-out is specific to the device or
                            browser on which it is exercised, you will need to
                            opt out on every browser and device that you use.{" "}
                        </span>
                    </p>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                    <p className="c7">
                        <span className="c3">
                            We currently do not use third party advertising
                            cookies on our website. &nbsp;{" "}
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="7">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.ctzu7745k0ey"
                                style={{ display: "inline" }}
                            >
                                <span className="font-bold c16">
                                    U.S. State-Specific Disclosure
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c20 c23">
                        <span className="c15">
                            Some U.S. states have enacted comprehensive privacy
                            laws related to the &lsquo;sale&rsquo; or
                            &lsquo;sharing&rsquo; of personal information for
                            advertising purposes, as well as use of
                            &lsquo;sensitive&rsquo; personal information. CTrees
                            does not &lsquo;sell&rsquo; or &lsquo;share&rsquo;
                            personal information for targeted or
                            cross-contextual behavioral advertising purposes nor
                            collect sensitive personal information.{" "}
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="8">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.168ekaify34v"
                                style={{ display: "inline" }}
                            >
                                <span className="font-bold">
                                    UK/EEA Residents Notice &nbsp;
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c20 c23">
                        <span className="c15">
                            Residents of the United Kingdom (&ldquo;
                        </span>
                        <span className="font-bold">UK</span>
                        <span className="c15">
                            &rdquo;) and European Economic Area (&ldquo;
                        </span>
                        <span className="font-bold">EEA</span>
                        <span className="c15">
                            &rdquo;) are provided certain privacy rights under
                            the UK and EU General Data Protection Regulations
                            (&ldquo;
                        </span>
                        <span className="font-bold">GDPR</span>
                        <span className="c15">&rdquo;).</span>
                    </p>
                    <h2 className="c4" id="h.enabk8p14ngw">
                        <span className="c12">Legal Basis:</span>
                    </h2>
                    <p className="c20 c23">
                        <span className="c15">
                            Under the GDPR, we process &lsquo;Personal
                            Data&rsquo; (as defined in the GDPR) under the
                            following legal basis.
                        </span>
                    </p>
                    <table className="c31">
                        <tr className="c10">
                            <td className="c0" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c1">Processing Activity</span>
                                </p>
                            </td>
                            <td className="c11" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c1">
                                        Legal Basis under GDPR
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr className="c10">
                            <td className="c22" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">
                                        Providing our Services
                                    </span>
                                </p>
                            </td>
                            <td className="c22" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">Contract Fulfillment</span>
                                </p>
                            </td>
                        </tr>
                        <tr className="c10">
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">
                                        Improving our Website and Services
                                    </span>
                                </p>
                            </td>
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">Legitimate Interest</span>
                                </p>
                            </td>
                        </tr>
                        <tr className="c10">
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">
                                        Product, Marketing or Service-Related
                                        Communications{" "}
                                    </span>
                                </p>
                            </td>
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">Legitimate Interest</span>
                                </p>
                            </td>
                        </tr>
                        <tr className="c10">
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">Customer support </span>
                                </p>
                            </td>
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">
                                        Contract fulfillment{" "}
                                    </span>
                                </p>
                            </td>
                        </tr>
                        <tr className="c10">
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">
                                        Use of Pixel Tags or Cookies
                                    </span>
                                </p>
                            </td>
                            <td className="c2" colspan="1" rowspan="1">
                                <p className="c7">
                                    <span className="c3">Consent</span>
                                </p>
                            </td>
                        </tr>
                    </table>
                    <p className="c17 c7 c8">
                        <span className="c3"></span>
                    </p>
                    <h2 className="c17 c7 c21" id="h.8sio3nw655xy">
                        <span className="c12">Controller Designation:</span>
                    </h2>
                    <p className="c7 c17">
                        <span className="c3">
                            Under the GDPR, we are designated as a
                            &lsquo;Controller.&rsquo;
                        </span>
                    </p>
                    <h2 className="c17 c7 c21" id="h.vrvyw2gbe1t">
                        <span className="c12">Cross-Border Data Transfers: </span>
                    </h2>
                    <p className="c20 c23">
                        <span className="c15">
                            All data is stored in the United States. As such, if
                            you are a resident of the EEA, UK, or Switzerland,
                            we may transfer to, and store the data we collect
                            about you, to countries other than the country in
                            which the data was originally collected, including
                            the United States. Those countries may not have
                            equivalent data protection laws as the country in
                            which you provided the data. When we transfer your
                            data to other countries, we will protect the data as
                            described in this Privacy Policy and comply with
                            applicable legal requirements providing adequate
                            protection for the transfer of data to countries
                            outside the EEA, UK, and Switzerland. We rely on
                            Standard Contractual Clauses (&ldquo;
                        </span>
                        <span className="font-bold">SCCs</span>
                        <span className="c3">
                            &rdquo;) for the transfer of personal data to
                            countries that have not received an applicable
                            adequacy decision, as well as the UK&rsquo;s
                            &lsquo;International Data Transfer Addendum&rsquo;
                            to the SCCs.
                        </span>
                    </p>
                    <p className="c20 c23">
                        <span className="c3">
                            For more information on cross-border transfers of
                            your Personal Data or the appropriate safeguards in
                            place, please contact us at info@ctrees.org &nbsp;{" "}
                        </span>
                    </p>
                    <h2 className="c4" id="h.dlkqsaczm8je">
                        <span className="c12">
                            Additional Rights for UK or European Economic Area
                            Residents:
                        </span>
                    </h2>
                    <p className="c20 c23">
                        <span className="c15">
                            In addition to the rights granted above, if you are
                            a UK or EEA resident, the GDPR grants you the right
                            to lodge a complaint against us with your local data
                            protection authority. You can find your data
                            protection authority at{" "}
                        </span>
                        <span className="c29 c15">
                            <a
                                className="c32"
                                href="https://www.google.com/url?q=https://edpb.europa.eu/about-edpb/about-edpb/members_en&amp;sa=D&amp;source=editors&amp;ust=1728597283477736&amp;usg=AOvVaw0PEeiDkWCn7EEn4ACPtv0n"
                            >
                                https://edpb.europa.eu/about-edpb/about-edpb/members_en
                            </a>
                        </span>
                        <span className="c3">. </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="9">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.3wzztm5esm26"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">Security</span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c20 c23">
                        <span className="c15">
                            We protect information we collect about you by
                            maintaining physical, electronic and procedural
                            safeguards designed to prevent unauthorized access
                            to user data retained in our servers, however, due
                            to the inherent open nature of the internet, we
                            cannot ensure or warrant the security of any
                            information provided online. We take reasonable
                            precautions to protect your information both online
                            and offline. The servers on which we store data are
                            kept in a secure environment.
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="10">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.1olna5t4rvy8"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">Retention</span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c20 c23">
                        <span className="c3">
                            We will retain your subscriber information for as
                            long as your account or inquiry is active or as
                            needed to provide you with the website or any
                            requested services, and for a reasonable time
                            thereafter in accordance with our standard
                            procedures or as necessary to comply with our legal
                            obligations, to resolve disputes, and to enforce our
                            agreements. Even if we delete some or all of your
                            personal information, we may continue to retain and
                            use aggregate or anonymous data previously collected
                            and/or anonymize or aggregate your Personal
                            Information. Please note that we will not be liable
                            for disclosures of your data due to errors or
                            unauthorized acts of third parties.
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="11">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.77gfc99mur92"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">Third-Party Links</span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c20 c23">
                        <span className="c15">
                            Our Services may contain links to other websites or
                            services. We do not exercise control over the
                            information you provide, or is collected by these
                            third party websites. We encourage you to read the
                            privacy policies or statements of the other websites
                            you visit.
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="12">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.airf69i31pp2"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    Changes To This Privacy Policy
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c13 c7 c15">
                        <span className="c3">
                            We may update this Privacy Policy from time to time.
                            If we make material changes, we will communicate
                            directly with you via email and/or post the updated
                            Privacy Policy on this page with a &ldquo;Last
                            Updated&rdquo; effective date of the revisions. We
                            encourage you to look for updates and changes to
                            this Privacy Policy by checking this page when you
                            access our Services.{" "}
                        </span>
                    </p>
                    <ol className="c5 lst-kix_6uzd85a855oh-0" start="13">
                        <li className="c13 c7 c19 c15 c21 list-decimal">
                            <h1
                                id="h.sj2u95ae8gki"
                                style={{ display: "inline" }}
                            >
                                <span className="c16 font-bold">
                                    Contact Information{" "}
                                </span>
                            </h1>
                        </li>
                    </ol>
                    <p className="c13 c7 c15">
                        <span className="c15 c27">
                            If you have any privacy-related questions, you can
                            contact us at{" "}
                        </span>
                        <span className="c15">info@ctrees.org</span>
                        <span className="c16 c15 c18">&nbsp;or</span>
                    </p>
                    <p className="c7">
                        <span className="c3">CTrees </span>
                    </p>
                    <p className="c7">
                        <span className="c3">12 S Raymond Ave, Suite B</span>
                    </p>
                    <p className="c7">
                        <span className="c3">Pasadena, CA 91105</span>
                    </p>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                    <p className="c7 c8">
                        <span className="c3"></span>
                    </p>
                </div>
            </div>
        </div>
    );
}
