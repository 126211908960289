import { OpacityWrapper } from "@components";
import { fullWidthSubHeader } from "..";
import { blobs } from "@helpers/constants";

const cases = [
  {
    header: "Project Development",
    desc: "We accurately map variations of carbon stored in forest and non-forest landscapes, including coastal wetlands and mangroves, anywhere in the world.",
  },
  {
    header: "Jurisdiction Analysis",
    desc: "Compare CTrees data to national and subnational inventories, and dive deeper with customized geospatial data and reports.",
  },
  {
    header: "Forest Monitoring",
    desc: "Focus policy, investment, and law enforcement actions on deforestation hot spots.",
  },
];

export default function UseCases() {
  return (
    <>
      <div className={fullWidthSubHeader + " text-4xl flex-col text-center py-28 md:py-12 "}>
        Use Cases
        <div className="md:hidden mt-8 px-4 max-w-[600px] text-center font-normal text-lg">
          Our robust forest carbon data drives positive change for countries
          and jurisdictions, project developers, NGOs, and more.
        </div>
      </div>
      <OpacityWrapper className="p-4">
        <div className="flex flex-col items-center w-full md:mt-16 mb-28">
          <div className="hidden md:flex mb-20 max-w-[600px] text-center text-lg">
            Our robust forest carbon data drives positive change for countries
            and jurisdictions, project developers, NGOs, and more.
          </div>
          <div className="flex md:flex-row flex-col items-center md:items-start">
            {cases.map(({ header, desc }, i) => (
              <OpacityWrapper className="md:max-w-[250px] mt-20 md:mt-0 md:mr-20 last:mr-0 flex flex-col items-center">
                <img src={blobs[i + 6]} className="h-20 w-full mb-8" loading="lazy" />
                <div className="font-bold text-xl">{header}</div>
                <div className="pt-2 text-center tracking-wide">
                  {desc}
                </div>
              </OpacityWrapper>
            ))}
          </div>
        </div>
      </OpacityWrapper>
    </>
  );
}
