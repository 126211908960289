export function wrapImagesWithDiv(htmlString) {
  // Create a new DOMParser
  const parser = new DOMParser();

  // Parse the HTML string into a Document
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Get all img elements in the document
  const imgElements = doc.querySelectorAll('img');

  // Wrap each img element with a div with the class 'imgParent'
  imgElements.forEach((img) => {
    const div = doc.createElement('div');
    div.className = 'imgParent';

    // Insert the div before the img element, and then move the img element inside the div
    img.parentNode.insertBefore(div, img);
    div.appendChild(img);
  });

  // Serialize the modified document back to an HTML string
  const modifiedHtmlString = new XMLSerializer().serializeToString(doc);

  return modifiedHtmlString;
}