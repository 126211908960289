import { PageHeader, MetaTagHeader } from "@components";
import { beTagsFlat } from "@helpers/constants";
import { routesFlat } from "@routes";
import {
  FeatsAndBenefits,
  Method,
  ExploreTitleAndDesc,
  ProductNews,
} from "../../components";
import { header, featsAndBenefits, desc, superscript } from "./constants";

const metaProps = {
  title:
    "Measure carbon in all forest and non-forest land globally with CTrees",
  desc: "CTrees’ global land carbon map provides accurate estimates of carbon at 1-hectare resolution in all global forests and non-forest areas. Read the science behind our data product.",
  img: "",
};

export default function Carbon() {
  const pageHeader = { ...header, btnLink: routesFlat.contact, product: true };
  const method = { desc, superscript };
  const exploreProps = {
    ctaText: "Reach out to discuss data partnerships",
    link: routesFlat.contact,
  };

  return (
    <>
      <MetaTagHeader {...metaProps} />
      <PageHeader {...pageHeader} />
      <ExploreTitleAndDesc {...exploreProps}>
        <div className="mb-4">
          CTrees’ global land carbon map provides accurate estimates of carbon
          at 1-hectare resolution in all global forests and non-forest areas,
          including woodlands, grasslands, urban areas, croplands, coastal
          wetlands, and mangroves.
        </div>
        <div className="mb-4">
          Available annually from 2000 to present, the maps include spatial
          confidence level to allow prediction of carbon risks and returns. The
          maps are designed to meet accuracy requirements for calculating
          emission factors at project or jurisdictional levels.
        </div>
        Explore annual insights in CTrees’ free jurisdictional MRV tool – and
        contact our team to learn about licensing advanced maps and data.
      </ExploreTitleAndDesc>
      <FeatsAndBenefits feats={featsAndBenefits} />
      <Method {...method} />
      <ProductNews product={beTagsFlat.carbon} />
    </>
  );
}
