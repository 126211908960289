import * as React from "react";
import { motion, AnimatePresence } from "framer-motion";
import UpArrow from "@assets/up-arrow.svg";
import DownArrow from "@assets/down-arrow.svg";
import clsx from "clsx";

export default function Accordian({
  i = 1,
  expanded,
  setExpanded,
  header,
  children,
  staticEl,
}) {
  const isOpen = i === expanded;

  return (
    <div className={parentContainerStyles}>
      <motion.header
        initial={false}
        onClick={() => setExpanded(isOpen ? false : i)}
        className="flex w-full cursor-pointer flex-col"
      >
        <div className={accordianHeaderStyles}>
          <div className="w-[300px]">{header}</div>
          <img
            src={isOpen ? UpArrow : DownArrow}
            alt="dropdown arrow"
            className="h-[10px] fill-[black] md:ml-10"
          />
        </div>
      </motion.header>

      <div>{staticEl}</div>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.section
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", width: "100%" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className={accordianItemContainerStyles}>{children}</div>
          </motion.section>
        )}
      </AnimatePresence>
    </div>
  );
}

const parentContainerStyles = clsx(
  "flex flex-col justify-center border-y-[1px] border-black/[10%] bg-white py-6 text-2xl",
);
const accordianHeaderStyles = clsx(
  "flex w-full w-screen items-center justify-center text-center font-extrabold",
);
const accordianItemContainerStyles = clsx(
  "flex w-full flex-col flex-wrap items-center justify-center pb-8 md:flex-row md:pb-10",
);

//-------> StoryBook Accordian Exmaple <-------//
// function Example() {
//   // This approach is if you only want max one section open at a time. If you want multiple
//   // sections to potentially be open simultaneously, they can all be given their own `useState`.
//   const [expanded, setExpanded] = useState(0);

//   return accordionIds.map((i) => (
//     <Accordion key={i} i={i} expanded={expanded} setExpanded={setExpanded}>
//       stuff
//     </Accordion>
//   ));
// }

// const accordionIds = [0, 1, 2, 3];
