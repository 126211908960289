import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import gsap from "gsap";

let breakpoint = {
  desktop: true,
  phone: false,
  tablet: false,
};

export class Hero {
  private scene: THREE.Scene;
  private camera: THREE.PerspectiveCamera;
  private cameraTarget: THREE.Vector3;
  private material: THREE.ShaderMaterial;
  private uniforms;
  private loader: OBJLoader;
  private renderer: THREE.WebGLRenderer;
  private raf: number;

  private wrap: THREE.Group;
  private particles: THREE.Points;
  private particlesSettings = {
    scale: 2.7,
    colors: [
      new THREE.Color("#b6d9ff"),
      new THREE.Color("#46ce84"),
      new THREE.Color("#dff841"),
      new THREE.Color("#ff6b6b"),
      new THREE.Color("#d2d8e3"),
    ],
    colorProportions: [20, 30, 25, 10, 15],
  };

  private progress: HTMLElement;

  constructor(protected view: HTMLElement) {
    this.progress = document.querySelector(".js-progress");
    this.build();
    this.animate();
  }

  componentDidMount(prevState, prevProps): void {
    console.log({ prevProps });
  }

  private bind(): void {
    document.addEventListener("mousemove", this.onMouseMove);
  }

  private onMouseMove = (event): void => {
    const factorX = (event.clientX / this.view?.clientWidth) * 2 - 1;
    const factorY = (event.clientY / this.view?.clientHeight) * 2 - 1;
    const xRound = Math.round(factorX * 100) / 100;
    const yRound = Math.round(factorY * 100) / 100;
    gsap.to(this.wrap.rotation, {
      y: -xRound * 0.3,
      x: -yRound * 0.1,
      duration: 5,
      ease: "sine",
    });
  };

  private build(): void {
    this.scene = new THREE.Scene();

    this.camera = new THREE.PerspectiveCamera(
      40,
      this.view?.offsetWidth / this.view?.offsetHeight,
      1,
      10000
    );
    const zoom = 150 * (window.innerWidth / 1400);
    this.camera.position.set(
      0,
      -10,
      breakpoint.desktop ? zoom : breakpoint.phone ? 250 : 130
    );
    this.cameraTarget = new THREE.Vector3(0, -10.1, 0);

    this.uniforms = {
      uTime: { value: 0 },
      uShift: { value: 0 },
      uAlpha: { value: 0 },
      uSize: { value: breakpoint.desktop ? 2 : 4 },
      uScale: { type: 'f', value: (this.view.clientWidth / 1100) * 50 },
      uPixelRatio: { value: 3 },
      uPointTexture: {
        value: new THREE.TextureLoader().load(this.view?.dataset.particle),
      },
    };

    this.material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: `
attribute vec3 aColor;
attribute vec3 aRandomness;
attribute vec3 aMove;
attribute float aSize;
uniform float uSize;
uniform float uPixelRatio;
uniform float uScale;
uniform float uShift;
uniform float uVariety;
uniform float uTime;
varying vec3 vColor;

void main() {
    // vColor = color;
    vColor = aColor;

    vec3 displaced = position;

    displaced.x += uShift * aRandomness.x + aMove.z * cos(uTime + 1.0);
    displaced.y += uShift * aRandomness.y + aMove.y * sin(uTime + 0.5);
    displaced.z += uShift * aRandomness.z + aMove.z * sin(uTime);

    vec4 modelPosition = modelMatrix * vec4(displaced, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;

    gl_Position = projectedPosition;

    gl_PointSize = uSize * uPixelRatio + (aSize * uVariety);
    gl_PointSize *= uScale / length(viewPosition.xyz);
}                `,
      fragmentShader: `
precision mediump float;
varying vec3 vColor;
uniform float uAlpha;
uniform sampler2D uPointTexture;

void main() {
    gl_FragColor = vec4(vColor, uAlpha) * texture2D( uPointTexture, gl_PointCoord );
}
                `,
      depthTest: false,
      transparent: true,
      vertexColors: true,
    });

    const canvas = this.view?.getElementsByTagName("canvas")[0];
    this.renderer = new THREE.WebGLRenderer({ canvas, alpha: true });
    this.renderer.setPixelRatio(1); // window.devicePixelRatio );
    this.renderer.setSize(this.view?.clientWidth, this.view?.clientHeight);
    this.renderer.setClearColor(0x000000, 0); // the default

    this.wrap = new THREE.Group();
    this.scene.add(this.wrap);

    const file = "https://ctrees-website.s3.us-west-2.amazonaws.com/tree_good2.obj";
    this.loader = new OBJLoader();

    this.loader.crossOrigin = "anonymous";
    this.loader.loadAsync(file).then((object: THREE.Object3D) => {
      this.addGeometry((object.children[0] as THREE.Mesh).geometry);
      this.show();
      this.bind();
    });
  }

  private addGeometry(geometry): void {
    geometry.computeBoundingBox();
    geometry.center();

    geometry.computeVertexNormals();

    const amount = geometry.getAttribute("position").count;

    const colors = [];
    const randoms = [];
    const moves = [];
    const sizes = [];

    for (let i = 0; i < amount; i++) {
      randoms.push(-1 + Math.random() * 400 * (Math.random() < 0.5 ? 1 : -1));
      randoms.push(-1 + Math.random() * 400 * (Math.random() < 0.5 ? 1 : -1));
      randoms.push(
        -1 +
        Math.random() *
        400 *
        Math.random() ** 4 *
        (Math.random() < 0.5 ? 1 : -1)
      );

      const randomColor = this.getRandomColor();
      colors.push(randomColor.r);
      colors.push(randomColor.g);
      colors.push(randomColor.b);

      moves.push(Math.random() * (Math.random() < 0.5 ? 1 : -1) * 0.5);
      moves.push(Math.random() * (Math.random() < 0.5 ? 1 : -1) * 0.5);
      moves.push(Math.random() * (Math.random() < 0.5 ? 1 : -1) * 0.5);

      sizes.push(-1 + Math.random() * 2);
    }

    geometry.setAttribute(
      "aRandomness",
      new THREE.Float32BufferAttribute(randoms, 3)
    );
    geometry.setAttribute(
      "aColor",
      new THREE.Float32BufferAttribute(colors, 3)
    );
    geometry.setAttribute("aMove", new THREE.Float32BufferAttribute(moves, 3));
    geometry.setAttribute("aSize", new THREE.Float32BufferAttribute(sizes, 1));

    this.particles = new THREE.Points(geometry, this.material);
    this.particles.position.y = -10;
    this.wrap.add(this.particles);
  }

  private getRandomColor(): THREE.Color {
    const availableColors = this.particlesSettings.colors;
    const colorProportions = this.particlesSettings.colorProportions;

    const sum = colorProportions.reduce((total, num) => {
      return total + num;
    });

    let i = 0;
    let p = colorProportions[0];
    let r = Math.random() * sum;
    while (r > p) {
      i++;
      p += colorProportions[i];
    }

    return availableColors[i];
  }

  private show(): void {
    const uniforms = (this.material as THREE.ShaderMaterial).uniforms;

    gsap.to(uniforms.uAlpha, {
      value: 1,
      duration: 1,
      ease: "sine.out",
      delay: 0.5,
    });
    gsap.fromTo(
      this.particlesSettings,
      {
        scale: 10,
      },
      {
        scale: 2.7,
        duration: 3,
        ease: "power3",
        delay: 0.5,
      }
    );
    gsap.fromTo(
      this.particles.rotation,
      {
        y: THREE.MathUtils.degToRad(15),
      },
      {
        y: 0,
        duration: 5,
        ease: "sine.out",
        delay: 0.5,
      }
    );
  }
  private animate = () => {
    this.raf = requestAnimationFrame(this.animate);
    this.render();
  };

  private render(): void {
    this.camera.lookAt(this.cameraTarget);

    if (
      this.material &&
      this.material.uniforms &&
      this.material.uniforms.uTime
    ) {
      this.material.uniforms.uTime.value += 0.01; // this.settings.speed;
    }

    if (this.particles) {
      let s = (this.particlesSettings.scale * this.view?.clientWidth) / 1280;
      if (breakpoint.phone) {
        s = (this.particlesSettings.scale * this.view?.clientWidth) / 375;
      }
      this.particles.scale.x = s;
      this.particles.scale.y = s;
      this.particles.scale.z = s;

      this.particles.rotation.y -= 0.0002;
    }

    this.renderer.render(this.scene, this.camera);
  }
}
