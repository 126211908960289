export default function ProfileWithPhoto({ img, name, func, title }) {
  return (
    <div className="flex flex-col items-center md:w-[300px] min-w-[150px] max-w-[150px] text-black my-4 md:mx-6 flex-wrap">
      <div className={"md:w-[150px] md:h-[150px] w-[100px] h-[100px] overflow-hidden rounded-sm "}>
        {img ? (
          <img
            src={img}
            className="w-full rounded-sm grayscale"
            alt={`headshot of ${name}`}
          />
        ) : (
          <div className="h-full w-full bg-[#414340] rounded-md"></div>
        )}
      </div>
      <div className="flex flex-col items-center mt-5 bg-white/75 w-full md:p-0 bottom-0">
        <div className="font-bold text-sm flex text-center">{name}</div>
        <div className="text-sm capitalize text-xs text-center">
          {title ? title : func}
        </div>
      </div>
    </div>
  );
}

export function Profile({ name, focus, title, local }) {
  return (
    <div className="flex flex-col first:mt-0 md:first:my-8 mt-8 my-0 md:my-8 md:mx-10 mx-8 md:w-[200px]">
      <div className="flex flex-col items-center md:text-center text-sm">
        <div className="font-bold text-lg capitalize flex">{name}</div>
        <div className="font-bold">{focus}</div>
        <div className="py-2 text-center">{title}</div>
        <div className="text-xs text-green">{local}</div>
      </div>
    </div>
  );
}
