import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ArticleItem } from "../../news";
import { getProductNews } from "../helpers";
import { routesFlat } from "@routes";
import { tagsList } from "@helpers/constants";

export default function Latest({ product }) {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getProductNews(product).then((arr) => {
      if (arr.length > 0) {
        setArticles(arr);
      }
    });
  }, [product]);
  
  return (
    <>
      <div className={"text-center mt-14 font-bold text-4xl w-full"}>
        {tagsList.product[product]} News
      </div>
      <div className="flex flex-wrap flex-col md:flex-row my-10 justify-around w-full">
        {!!articles.length ? (
          <div className="-mb-10 flex w-full justify-around">
            {articles.map((item) => (
              <ArticleItem item={item} key={item.id} />
            ))}
          </div>
        ) : (
          <div className="flex my-4">
            <div className="h-[300px] -mr-60 w-[250px] -rotate-6 shadow-md border-2 border-black/10 rounded-md text-black/40"></div>
            <div className="h-[300px] w-[250px] bg-[white] shadow-md z-10 border-2 border-black/10 rounded-md text-black/30 p-10 text-4xl">
              <div>Articles coming soon.</div>
              <Link to={routesFlat.news}>
                <div className="text-sm tracking-wide py-2 mt-10 hover:bg-green/80 uppercase font-bold text-center text-white bg-green">See other articles</div>
              </Link>
            </div>
            <div className="h-[300px] w-[250px] bg-[white] shadow-md border-2 -ml-60 rotate-6 border-black/10 rounded-md text-black/40"></div>
          </div>
        )}
      </div>
    </>
  );
}
