import { useState } from "react";
import {
  PageHeader,
  Accordian,
  FullWidthHeader,
  MetaTagHeader,
} from "../../components";
import { ProfileWithPhoto, Profile } from "./components";
import { staff, bodir, advisors, sassan } from "./constants";
import { shuffle } from "@helpers";
import * as styles from './styles'

// randomly generate array to populate different order on each render
const usStaff = staff.us;
const frStaff = staff.fr;
const bzStaff = staff.bz;
const dnStaff = staff.dn;
const shuffledUs = shuffle(usStaff);
const shuffledWithSassan = sassan.concat(shuffledUs);

export default function About() {
  const [expanded, setExpanded] = useState(0);

  return (
    <>
      <MetaTagHeader {...metaProps} />
      <div className="flex justify-center md:mb-20">
        <div className="flex flex-col w-full items-center">
          <PageHeader {...pageHeader} />

          {/* why ctrees */}
          <FullWidthHeader>Who We Are</FullWidthHeader>
          <div className={styles.whoContainer}>
            <div>
              We are scientists and engineers with over 20 years of experience
              in building global carbon monitoring systems and solutions that
              have informed climate change policies.
            </div>
            <div className="mt-10">
              Our leading experts work alongside public and private partners to
              provide operational data to national and state governments,
              project developers, investors, and decision makers.
            </div>
            {/* {descriptors.map((text, i) => (
            <Paragraph key={i} {...text} />
          ))} */}
          </div>

          <FullWidthHeader>Team</FullWidthHeader>
          {/* dropdown display */}
          <div className={styles.containerStyles}>
            <div className="flex flex-col">
              <div className={styles.staffContainer}>
                <div className={styles.staffChildContainer}>
                  {shuffledWithSassan.map((person, i) => (
                    <ProfileWithPhoto key={i} {...person} />
                  ))}
                </div>
              </div>
            </div>

            <Accordian
              expanded={expanded}
              setExpanded={setExpanded}
              i={3}
              header={"Board of Directors"}
            >
              <div className={styles.accordianParent}>
                {bodir.map((person, i) => (
                  <ProfileWithPhoto key={i} {...person} />
                ))}
              </div>
            </Accordian>

            <Accordian
              expanded={expanded}
              setExpanded={setExpanded}
              i={1}
              header={"Scientific Partners"}
            >
              <div className={styles.accordianParent + " md:flex-col"}>
                <div className={styles.countryHeader}>Denmark</div>
                <div className={styles.countryContainer}>
                  {dnStaff.map((person, i) => (
                    <Profile key={i} {...person} />
                  ))}
                </div>
                <div className={styles.countryHeader}>France</div>
                <div className={styles.countryContainer}>
                  {frStaff.map((person, i) => (
                    <Profile key={i} {...person} />
                  ))}
                </div>
                <div className={styles.countryHeader}>Brazil</div>
                <div className={styles.countryContainer}>
                  {bzStaff.map((person, i) => (
                    <Profile key={i} {...person} />
                  ))}
                </div>
              </div>
            </Accordian>

            <Accordian
              expanded={expanded}
              setExpanded={setExpanded}
              i={4}
              header={"Advisors"}
            >
              <div className={styles.accordianParent}>
                {advisors.map((person, i) => (
                  <Profile key={i} {...person} />
                ))}
              </div>
            </Accordian>
          </div>
        </div>
      </div>
    </>
  );
}

const metaProps = {
  title: "About: The trusted source for forest carbon data",
  desc: "CTrees scientists and engineers have more than 20 years of experience in building global carbon monitoring systems. Learn more about our team. ",
  img: "",
};
const pageHeader = {
  title: "The trusted source for forest carbon data",
  desc: "CTrees is the first global system to measure carbon and activity in all the world’s forests. Our mission is to track carbon in every tree on the planet.",
  img: "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images/static/heatmap/2.png",
};
