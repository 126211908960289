export const MenuToggle = ({ toggle, isOpen }) => {
  const isOpenBg = isOpen
    ? " bg-green md:hover:bg-gray text-white"
    : "bg-white/75 md:hover:bg-green";
  return (
    <button
      onClick={toggle}
      className={"rounded-md flex absolute items-center justify-center md:mt-2 p-1 " + isOpenBg}
    >
      <div className={"flex flex-col p-2 " + (isOpen ? 'm-2' : '')}>
        {isOpen ? (
          <>
            <div className="w-[25px] rotate-45 bg-black h-[1px]"></div>
            <div className="w-[25px] rotate-[135deg] bg-black h-[1px]"></div>
          </>
        ) : (
          <>
            <div className="w-[40px] bg-black h-[1px] mb-2"></div>
            <div className="w-[40px] bg-black h-[1px] mb-2"></div>
            <div className="w-[40px] bg-black h-[1px]"></div>
          </>
        )}
      </div>
    </button>
  );
};
