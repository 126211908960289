import { PageHeader, MetaTagHeader } from "@components";
import { beTagsFlat } from "@helpers/constants";
import { routesFlat } from "@routes";
import {
    FeatsAndBenefits,
    Method,
    ExploreTitleAndDesc,
    ProductNews,
} from "../../components";
import { header, featsAndBenefits, desc, superscript } from "./constants";

export const metaProps = {
    title: "Measure tropical forest degradation with CTrees’ REDD+AI platform",
    desc: "CTrees’ REDD+AI platform measures forest degradation across the tropics.",
    imgLink:
        "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images/CTrees-redd%2Bai.png",
        // "https://ctrees-website.s3.us-west-2.amazonaws.com/news/24_10+REDDAI+Roads.png",
    imgAlt: "REDD+AI landing page map with Brazil highlighted in yellow and a left side panel of metrics",
};

export default function Activity() {
    const pageHeader = {
        ...header,
        btnLink: routesFlat.activityMap,
        product: true,
    };
    const method = { desc, superscript };
    const exploreProps = {
        product: true,
        ctaText:
            "Measure tropical forest degradation and tree cover loss with REDD+AI",
        link: routesFlat.activityMap,
    };

    return (
        <>
            <MetaTagHeader {...metaProps} />
            <PageHeader {...pageHeader} />
            <ExploreTitleAndDesc {...exploreProps}>
                <div className="mb-4">
                    REDD+AI is the first system to quantify and map tropical
                    forest degradation globally. The open platform includes maps
                    and statistics to show how much forest degradation can be
                    attributed to logging, forest fire, and roads for every
                    tropical forest country and its subnational jurisdictions
                    from 2017 to 2023. REDD+AI also measures tree cover loss.
                </div>
                Use REDD+AI to pinpoint exactly where and to what extent forest
                degradation and tree cover loss are occurring across the entire
                tropics.
            </ExploreTitleAndDesc>
            <FeatsAndBenefits feats={featsAndBenefits} />
            <Method {...method} />
            <ProductNews product={beTagsFlat.activity} />
        </>
    );
}
