import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const style =
  "capitalize font-black font-body text-white tracking-wide text-sm  min-w-[200px] flex items-center justify-center py-4";
const enabledStyle = style + " bg-green";
const disabledStyle = style + " bg-black/25";

export const PrimaryBtn = ({
  children,
  link,
  className,
  onClick,
  enabled = true,
  newWindow = false
}) => {
  const styles = `${className} ${enabled ? enabledStyle : disabledStyle}`;

  return enabled ? (
    <Link to={link} target={newWindow && "_blank"} className='w-full'>
      <motion.div
        whileHover={{ scale: 0.95 }}
        whileTap={{ scale: 0.9 }}
        className={styles}
        onClick={onClick}
      >
        {children}
      </motion.div>
    </Link>
  ) : (
    <div className={styles}>{children}</div>
  );
};
