export const header = {
  title: "Land Carbon Map",
  desc: "Measure carbon in all forest and non-forest land globally",
  img:
    "https://ctrees-website.s3.amazonaws.com/website_images/product/carbon_pg.jpg",
};

// for each feature, add as an object item with title and desc attr inside of featsAndBenefits array
export const featsAndBenefits = [
  {
    title: "Patterns & processes",
    desc: "See how carbon is distributed across landscapes at 1 hectare resolution, and understand the impacts of climate change, soil, and disturbance processes.",
  },
  {
    title: "Historical trends",
    desc: "Track changes of carbon annually from 2000 to present to inform baseline and additionality of nature-based solutions at all scales.",
  },
  {
    title: "Global stocktake",
    desc: "Evaluate global progress  and country-level action on natural climate solutions.",
  },
  {
    title: "Reliability & confidence",
    desc: "All maps are accompanied with spatial confidence level to allow prediction of carbon risks and returns. ",
  },
  {
    title: "All vegetation",
    desc: "Estimate carbon for forests and non-forest areas, including woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves. ",
  },
  {
    title: "Available now",
    desc: "Annual insights are available in CTrees’ free jurisdictional MRV tool, with advanced maps and data available by license.",
  },
];

// for each paragraph in methodology description, add as string item inside of method array
export const desc = [
  "CTrees’ global land carbon maps are developed using a combination of local to national ground inventory data and remote sensing measurements from air and space. The main objectives of the maps are: (1) to provide accurate estimates of global forest carbon at a scale that can reliably capture landscape variations, and (2) to extend the estimates, for the first time in any global map product, to all woody vegetation in non-forest areas, including woodland savanna, mixed shrub and grasslands, treed landscapes in urban areas and croplands, coastal wetlands, and mangroves. The maps are produced annually at 1-ha spatial resolution, along with the estimation uncertainty and confidence intervals.",
  "CTrees’ methodology follows peer-reviewed approaches published by our team and benefits from findings of scientific results in literature [1][2]. The methodology also includes several novel techniques to map land carbon stocks and stock changes over time: (1) Use of light detection and ranging (Lidar) measurements from NASA’s ICESat and GEDI satellites to develop a global data set of vegetation structure and estimates of above ground live biomass, calibrated by more than 1.5 million ground and lidar inventory plot data points. (2) Development of change detection machine learning algorithms customized for more than 800 ecoregions globally to systematically map biomass using satellite radar and optical imagery. (3) Estimation of below-ground live biomass carbon stocks using ecological models and standards for global forest and non-forest vegetation types. (4) Estimation of uncertainty at mapping units or aggregated scales using advanced error propagation models.",
  "CTrees’ land carbon maps are designed to meet accuracy requirements for calculating emission factors at project or jurisdictional levels. The maps will be regularly updated as new data sources become available, including radar satellite measurements from the NASA-ISRO Synthetic Aperture Radar (NISAR) mission (anticipated early 2024) and the European Space Agency’s Biomass mission (anticipated 2025).",
];
// for each numbered reference under methodology, add as string item inside of superscript array
export const superscript = [
  "[1] Saatchi, Sassan S., et al. Benchmark map of forest carbon stocks in tropical regions across three continents. Proceedings of the national academy of sciences 108.24 (2011): 9899-9904.",
  "[2] Xu, L., Saatchi, S. S., Yang, Y., Yu, Y., Pongratz, J., Bloom, A. A., ... & Schimel, D. (2021). Changes in global terrestrial live biomass over the 21st century. Science Advances, 7(27), eabe9829.",
];
