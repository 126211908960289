import { FullWidthSubHeader, PrimaryBtn } from "@components";

export function ExploreTitleAndDesc({ product, children, link, ctaText, newWindow = false }) {
  return (
    <div className="md:-ml-4">
      <FullWidthSubHeader>
        <div className={innerContainer + ` md:justify-around justify-center md:py-20 py-10`}>
          <div className="max-w-[500px] font-normal text-center md:text-left text-xl">{children}</div>
          <div className="flex flex-col items-center text-center">
            <div className="mb-10 mt-10 md:mt-0 md:font-normal max-w-[400px]">{ctaText}</div>
            <div className="">
              <PrimaryBtn link={link} newWindow={newWindow}>
                {product ? "Explore" : "Contact Us"}
              </PrimaryBtn>
            </div>
          </div>
        </div>
      </FullWidthSubHeader>
    </div>
  );
}

export function ExploreTitleOnly({ ctaText, link }) {
  return (
    <div className="md:-ml-4">
      <FullWidthSubHeader>
        <div className={innerContainer + " justify-center py-10 px-10 md:px-0"}>
          <div className="md:mr-10 text-center md:text-left">{ctaText}</div>
          <div className="md:w-20 mt-10 md:mt-0">
            <PrimaryBtn link={link}>Explore Data</PrimaryBtn>
          </div>
        </div>
      </FullWidthSubHeader>
    </div>
  );
}

const innerContainer = "flex w-full md:flex-row flex-col items-center px-4"