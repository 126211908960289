import HubspotForm from "react-hubspot-form";
import Skeleton from "react-loading-skeleton";
import { staticTree } from "@helpers/constants";
import { MetaTagHeader } from "../../components";

const metaProps = {
  title: "Contact forest science and data experts at CTrees",
  desc: "Get in touch with us to see how our products can support your goals.",
  img: "",
};

export default function Contact() {
  return (
    <>
    <MetaTagHeader {...metaProps} />
      <div className="flex md:flex-row flex-col h-full">
        <div className="md:w-1/2 md:mt-0 mt-[160px] flex md:flex-col flex-col-reverse items-center border-r-[1px] border-black/[10%]">
          <div className="text-5xl my-10 md:mt-[160px] font-bold capitalize font-extrabold">
            Let’s Talk Trees
          </div>
          <img src={staticTree} className="md:w-[600px] aspect-[1.5]" />
        </div>

        <div className="md:w-1/2 flex justify-center md:mt-40">
          <div className="flex flex-col justify-center w-full mb-10 max-w-[400px]">
            <div className="mb-10 text-xl text-center md:text-left">
              Get in touch with us to see how our products can support your
              goals.
            </div>
            <div className="max-w-[500px] h-full md:py-0 md:pt-0 pt-10 px-4 md:px-0 w-full">
              <HubspotForm
                portalId="22828643"
                formId="8619e3f1-b45e-4122-aa17-2ed40ad000b1"
                loading={
                  <div className="w-full">
                    Loading...
                    {Array(6)
                      .fill(null)
                      .map((i) => (
                        <Skeleton key={i} className="h-[50px] w-full" />
                      ))}
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
