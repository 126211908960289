import { useEffect } from "react";
import { Products, Partners, Latest, UseCases } from "./components";
import { ScrollProgress, MetaTagHeader, LatestMarquee } from "../../components";
import { Hero } from "./components/Tree.tsx";

const metaProps = {
    title: "CTrees: Track forest carbon anywhere on the planet",
    desc: "Get accurate information on demand with the only global forest carbon and activity data platform.",
    img: "",
};

export default function Landing() {
    useEffect(() => {
        new Hero(document.querySelector('[data-component="Hero"]'));
    }, [this]);

    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className="flex w-full flex-col items-center justify-center">
                <ScrollProgress />
                <div className="md:hidden mt-20 -mb-40">
                    <LatestMarquee />
                </div>
                <div className="z-80 relative mb-20 flex h-navscreen w-full items-center justify-center">
                    {/* ANIMATED TREE */}
                    <div
                        className="background background--hero -mt-[100px] h-[400px] w-screen md:-mt-[8rem] md:h-[700px]"
                        data-component="Hero"
                        data-particle="https://assets.huncwot.net/ctrees/particle3.png"
                    >
                        <canvas className="background__canvas h-screen w-screen"></canvas>
                    </div>
                    <div className="js-progress"></div>
                    {/* SUB HEADER TEXT */}
                    <div className="absolute bottom-0 z-30 -mb-20 self-center">
                        <div className={`my-0 flex w-full flex-col md:mx-0`}>
                            <div
                                className={`flex flex-col items-center bg-white/25 px-10 md:px-0`}
                            >
                                <div
                                    className={`max-w-[400px] text-center text-3xl font-black leading-none md:max-w-[600px] md:text-[50px]`}
                                >
                                    Track forest carbon anywhere on the planet
                                </div>
                                <div
                                    className={`opacity-1 mb-8 mt-4 max-w-[450px] text-center text-xl leading-tight md:text-2xl`}
                                >
                                    Get accurate information on demand with the
                                    only global forest carbon and activity data
                                    platform.
                                </div>
                            </div>
                        </div>
                        <div className="hidden md:flex">
                            <LatestMarquee />
                        </div>
                    </div>
                </div>
                <Products />
                <UseCases />
                <Latest />
                <Partners />
            </div>
        </>
    );
}

export const fullWidthSubHeader =
    "py-12 flex justify-center font-extrabold border-y-[1px] border-black/[10%] md:w-overlines bg-white";
