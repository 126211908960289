import jsonp from "jsonp";

const mailchimpUrl =
  "https://ctrees.us11.list-manage.com/subscribe/post-json?u=55dcc98dcd6ca13f8c28c68d3&amp;id=c32db32ce1&amp;f_id=00aebae0f0";

// event: if you need to impact form behavior
// email: to enroll user in hubspot
// setSuccess: fn to enable pass completed signup message to parent
export default function signUp(event = "", email, setSuccessMsg) {
  event?.preventDefault();
  
  jsonp(`${mailchimpUrl}&EMAIL=${email}`, { param: "c" }, (_, data) => {
    const { msg } = data;
    setSuccessMsg(msg)
  }) 
}
