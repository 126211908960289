import { PageHeader, MetaTagHeader } from "@components";
import { routesFlat } from "@routes";
import { beTagsFlat } from "@helpers/constants";
import {
  FeatsAndBenefits,
  Method,
  ExploreTitleAndDesc,
  ExploreTitleOnly,
  ProductNews,
} from "../../components";
import { header, featsAndBenefits, desc, superscript } from "./constants";

const metaProps = {
  title: "Monitor the world’s forests with Land Use Change Alerts (LUCA)",
  desc: "LUCA is a radar-based alert system for forests, providing near-real-time information on where and when disturbances occur anywhere in the world. See alert data in the free LUCA app.",
  img: "",
};

export default function Luca() {
  const pageHeader = { ...header, btnLink: routesFlat.lucaMap, product: true };
  const method = { desc, superscript };
  const exploreProps = {
    product: true,
    ctaText: "See alert data in the LUCA app",
    link: routesFlat.lucaMap,
  };

  return (
    <>
      <MetaTagHeader {...metaProps} />
      <PageHeader {...pageHeader} />
      <ExploreTitleAndDesc {...exploreProps}>
        <div className="mb-4">
          LUCA is the world’s first global radar-based forest disturbance alert
          system, providing information every two weeks on where and when
          disturbances in forests occur anywhere in the world, in almost any
          weather condition.
        </div>
        See alert data for 2018 to present in all forest biomes, including
        tropical humid, tropical dry, temperate, and boreal forests.
      </ExploreTitleAndDesc>
      <FeatsAndBenefits feats={featsAndBenefits} />
      <Method {...method} />
      {/* <FAQ qArr={faq} /> */}
      <ExploreTitleOnly {...exploreProps} />
      <ProductNews product={beTagsFlat.luca} />
    </>
  );
}
