import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function LucaMap() {
  const [loading, setLoading] = useState(true);
  return (
    <div className="w-full h-navscreen mt-[80px]">
      {loading && (
        <div>
          <Skeleton className="h-[50px] mb-4" count={1} />
          <div className="flex">
          <Skeleton className="h-[100px] ml-10 !w-[300px]" count={5} />
          <Skeleton className="h-[515px] ml-10 !w-[700px]" />
          </div>
        </div>
      )}
      <iframe
        src="https://global-forest-structure.projects.earthengine.app/view/luca-viewer#alerts=1;lon=-42.384952;lat=4.987819;zoom=3"
        className="w-full h-full"
        onLoad={() => setLoading(false)}
      />
    </div>
  );
}
