import { useState } from "react";
import clsx from "clsx";
import Skeleton from "react-loading-skeleton";
import { MetaTagHeader } from '@components'
import { metaProps } from "..";

export default function ActivityMap() {
    const [loading, setLoading] = useState(true);
    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className="mt-[80px] h-navscreen w-full">
                {loading && (
                    <div>
                        <Skeleton className="mb-4 h-[50px]" count={1} />
                        <div className="flex">
                            <Skeleton
                                className="ml-10 h-[100px] !w-[300px]"
                                count={5}
                            />
                            <Skeleton className="ml-10 h-[515px] !w-[700px]" />
                        </div>
                    </div>
                )}
                <iframe
                    src={process.env.REACT_APP_ACTIVITY_URL}
                    className={containerStyle}
                    onLoad={() => setLoading(false)}
                    allow="clipboard-write"
                />
            </div>
        </>
    );
}

const containerStyle = clsx(
    `fixed left-0 top-0 z-40 h-screen w-full lg:relative lg:mt-[80px] lg:h-navscreen`,
);
