import { PropTypes } from "prop-types";
import { FullWidthSubHeader, OpacityWrapper } from "@components";
import { staticTree } from "@helpers/constants";

export default function Method({ desc, superscript }) {
  return (
    <div className="flex flex-wrap w-full justify-center">
      <div className="pt-16 md:pt-0 px-4 md:px-0 flex flex-wrap w-full justify-center items-center">
        <FullWidthSubHeader>Methodology</FullWidthSubHeader>
        <OpacityWrapper className="flex md:flex-row flex-col items-center">
          <div className="md:w-1/2 flex justify-center h-full">
            <OpacityWrapper className="py-10 md:py-0">
              <img src={staticTree} className="md:w-[600px] h-full" />
            </OpacityWrapper>
          </div>
          <div className="md:w-1/2 md:p-10 pb-20">
            {desc.map((paragraph) => (
              <div className="mb-4">{paragraph}</div>
            ))}
            {superscript.map((item) => (
              <div className="mb-2 text-xs">{item}</div>
            ))}
          </div>
        </OpacityWrapper>
      </div>
    </div>
  );
}

Method.propTypes = {
  desc: PropTypes.string,
};
