import clsx from "clsx";

export default function JMRVMap() {
    return (
        <iframe
            className={containerStyle}
            src={process.env.REACT_APP_JMRV_URL}
        />
    );
}

const containerStyle = clsx(
    `fixed left-0 top-0 z-40 h-screen w-full bg-black lg:relative lg:mt-[80px] lg:h-navscreen`,
);