import { FullWidthSubHeader, OpacityWrapper } from "@components";
import { blobs } from "@helpers/constants";

export default function FeatAndBens({ feats }) {
  return (
    <div className="flex flex-wrap w-full justify-center mb-0">
      <FullWidthSubHeader>Features & Benefits</FullWidthSubHeader>
      <div className="flex justify-center w-full -mt-10">
        <div className="flex pb-16 md:p-10 mt-20 md:mt-0 flex-wrap w-full justify-center max-w-[1200px]">
          <div className="flex justify-center flex-wrap mt-10">
            {feats.map(({ title, desc }, i) => {
              return (
                <OpacityWrapper
                  key={title}
                  className="flex flex-col items-center md:my-6 mb-20 last:mb-0 max-w-[300px] px-10"
                >
                  <img src={blobs[i + 1]} className="h-20 w-full mb-8" />
                  <div className="flex flex-col md:mt-0">
                    <div className="flex items-center justify-center text-center">
                      <div className="text-xl font-bold">{title}</div>
                    </div>
                    <div className="mt-2 text-md text-center">{desc}</div>
                  </div>
                </OpacityWrapper>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
